import { useState, useEffect, useContext, useRef } from 'react';
import InfinityIcon from "../../assets/images/InfinityIconBlack.png";
import eyeDrkGrey from "../../assets/images/eyeDrkGrey.png";
import HeartFillOutline from "../../assets/images/HeartFillOutline.png";
import VerifiedDarkGreen from "../../assets/images/VerifiedDarkGreen.webp";
import VerifiedOutlineIcon from "../../assets/images/VerifiedOutlineIcon.webp";
import TimeIcon from "../../assets/images/TimeIcon.png";
import CardIcon from "../../assets/images/CardIcon.png";
import { starRating, overallRating } from "../../components/util/stars";
import { useNavigate, Link } from 'react-router-dom';
import { MainContext } from '../../App';
import axios from 'axios';

export function reserveModal (service, closeModal) {

    const closeModalWithToast = async () => {
        //console.log(service, document.querySelector("#reserve-form input[name='name']").value, document.querySelector("#reserve-form input[name='email']").value);
        let body = {};
        let host = process.env.REACT_APP_SITE_HOST; //window.location.host;

        body["subject"] = "Service Pack reservation request";
        body["firstname"] = document.querySelector("#reserve-form input[name='name']").value;
        body["email"] = document.querySelector("#reserve-form input[name='email']").value;
        body["service_pack_name"] = service.name;
        body["service_pack_url"] = host + "/service/" + service.slug;
        body["service_pack"] = service.id;

        let res = await axios.post(`/mail/${0}/reserve-service`, body);
        let data = res.data;
        //console.log(res.data);

        closeModal();

        /*
          body["service_packs"] = [service.id].concat(profile.service_packs.map(i=>i.id) || []);
          body["name"] = profile?.name;
          let res = await axios.post(`/service-provider/create/${profile.user.id}`, body);
        */
    };

    return (
       <div className="card-body padding-top-20 padding-0">

          <div className="d-flex flex-direction-col">
            <h3>
              Reserve your spot for this service pack
            </h3>

            <p className="body-text">{"Fill in your details below to reserve your spot for when the  " + service.name + "  becomes available." }</p>

            <form id="reserve-form">
              <input
                type='text'
                name='name'
                className='input-text with-border margin-top-10'
                placeholder='Your Full Name'
              />
              <input
                type='email'
                name='email'
                className='input-text with-border margin-top-10'
                placeholder='Your Email address'
              />
              <div className="d-flex justify-content-center margin-top-40 w-100">
                <button onClick={(event)=>closeModalWithToast(event)} type="button" className="button button-text w-100">Submit Request</button>
              </div>
            </form>
          </div>

        </div>
    );

};


export default function ServiceCard({obj, buyerView=true, pending=false, eventTrigger=null, canEdit=false, canRemove=false, removeService=null, hideAction=null, target=null})  {

    const card = useRef(null);
    const {mainModalTrigger} = useContext(MainContext);
    const navigate = useNavigate();
    const [inst, setInst] = useState(obj);
    const [sp, setSP] = useState();
    const [displayType, setDisplayType] = useState("standard");
    const [gradient, setGradient] = useState({});
    const [spotsRemaining, setSpotsRemaining] = useState(10);
    const [comingSoon, setComingSoon] = useState();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    useEffect(()=> {
        let pro = inst.service_provider[0];
        setSP(pro);

        if (inst.is_internal === true) {
            if ( inst?.images.length > 0 && inst?.images.filter(i=>i.type==="service_pack_card_image").length > 0 ) {
                setDisplayType("internal");
            }
            setSpotsRemaining(inst?.note);
        } else {
            if (!pro.is_accepting_clients) { setSpotsRemaining(0); }
            else if (pro && pro.service_capacity === "99") { setSpotsRemaining(<img src={InfinityIcon} width="15px"/>); }
            else if (pro && pro.service_capacity) { setSpotsRemaining(pro.service_capacity); }
        }


        if (inst?.coming_soon_at) {
            let date = new Date(inst?.coming_soon_at);
            let cs_str = date.getDate() + " " + monthNames[date.getMonth()];
            setComingSoon(cs_str);
        }

        if (inst.is_internal && displayType === "standard" && inst.colour_tint !== undefined) {
            setGradient({backgroundImage: `linear-gradient(#fff 18%, ${inst.colour_tint} 71%, #fff) !important`});
            card.current.style.backgrounImage = `linear-gradient(#fff 18%, ${inst.colour_tint} 71%, #fff) !important`;
        }

    },[]);

    const Checkout = (event) => {
        event.preventDefault();
        localStorage.setItem('selectedServicePackId', inst.id);
        localStorage.setItem('selectedProviderId', sp.id);
        navigate('/checkout');
    };

    const getStyle = () => {
        if (displayType === "standard" && inst.colour_tint !== undefined) {
            return {backgroundImage: `linear-gradient(#fff 18%, ${inst.colour_tint} 71%, #fff)`};
        }
        return {};
    };

    const reserveService = (event) => {
        console.log("reserveService");
        if (comingSoon) {
            event.preventDefault();
            mainModalTrigger(reserveModal(inst, closeModal)) ;
        }
        return;
    };

    const closeModal = () => {
        mainModalTrigger();
    };

    return (
        <>
          <a ref={card} href={inst.coming_soon_at ? `#` : `/service/${inst.slug}`} className={displayType === "internal" ? "slide-box margin-right new colour-tint w-inline-block pro-delivered internal service-card" : "slide-box margin-right new  w-inline-block w-clearfix pro-verified service-card"}
             style={inst?.colour_tint ? {backgroundImage: `linear-gradient(#fff 18%, ${inst.colour_tint} 71%, #fff)`} : {}} onClick={reserveService}
             target={target ? target : "" }
          >
            <div className="slide-text-container">
              <div className="w-clearfix">
                <div className="wishlist-button">
                  <img src={HeartFillOutline} loading="lazy" width="26" alt=""/>
                </div>

                { inst.is_internal ? (
                    <>
                      {inst?.coming_soon_at ? (
                          <div className="verified-panel dark">
                            <div className="verified-row">
                              <img loading="lazy" src={VerifiedDarkGreen} height="16" width="1" alt="" className="image-17" style={{opacity: 0}}/>
                              <h5 className="verified-text no-bottom-space dark">Coming {comingSoon}</h5>
                            </div>
                          </div>
                      ) : (

                          <div className="verified-panel dark-green">
                            <div className="verified-row">
                              <img loading="lazy" src={VerifiedDarkGreen} width="16" alt="" className="image-17"/>
                              <h5 className="verified-text no-bottom-space dark-green">Pro Delivered</h5>

                            </div>
                          </div>
                      )}
                    </>
                ) : (
                    <>

                      { (obj?.service_provider && obj.service_provider.is_verified_pro) || (obj?.service_provider && obj.service_provider[0].is_verified_pro) ? (
                          <div className="verified-panel dark-green">
                            <div className="verified-row">

                              <img src={VerifiedDarkGreen} width="16" alt="" className="image-17" loading="lazy" />
                              <h5 className="verified-text no-bottom-space dark-green">Verified Pro</h5>

                            </div>
                          </div>
                      ) : (
                          <div className="verified-panel light-green">
                            <div className="verified-row">
                              <img src={VerifiedOutlineIcon} width="16" alt="" loading="lazy" />
                              <h5 className="verified-text no-bottom-space light-green">Verified</h5>
                            </div>
                          </div>
                      )}

                    </>
                )}

                <div className="spot-count">
                  <div className="spot-row">
                    <img loading="lazy" src={VerifiedDarkGreen} width="16" alt="" className="image-10"/>
                    <h5 className="verified-text no-bottom-space drk-grey">{spotsRemaining} spots left</h5>
                  </div>
                </div>
                <div className="div-block small-top-space">
                  <h3 style={{textOverflow: "ellipsis", overflow: "hidden", display:"inline-block" }} className="sp-card card-heading display-inline new text-left">{inst && inst?.name.length > 40 ? inst?.name.substring(0,40) + "..." : inst?.name  }</h3>
                </div>
                <div className="div-block">
                  <div style={{textOverflow: "ellipsis", maxHeight: "50px", overflow: "hidden"}} className="body-text">{inst?.header_text && inst?.header_text.length > 80 ? inst.header_text.substring(0,80) + "..." : inst?.header_text && inst.header_text  } </div>
                </div>
                <div className="div-block no-bottom-space">
                  <div className="row-icons-text row-align-center">
                    <div className="div-block-13">
                      <img loading="lazy" src={CardIcon} width="25" alt="" className="icon-row"/>

                      <h5 className="body-text no-bottom-space tiny-space-right">{inst && inst?.tiers && inst?.tiers.length > 0 && "From"} R{inst && inst?.tiers && inst?.tiers.length > 0  ? inst?.tiers[0].cost?.toLocaleString() : inst?.cost?.toLocaleString()} {inst && !inst?.is_once_off && "p/m"}</h5>

                    </div>
                    <div className="div-block-13 no-space">
                      <img src={TimeIcon} width="25" alt="" className="icon-row" loading="lazy"/>
                      <h5 className="body-text no-bottom-space">{inst?.delivery_time_in_days} days</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            { inst && inst.is_internal && displayType === "internal" ? (

                <div className="image-container2">
                  <div className="image-div">
                    { inst && inst?.images.length > 0 && (
                        <>
                          { inst?.images.filter(i=>i.type==="service_pack_card_image").length > 0 ? (

                              <img src={inst?.images.filter(i=>i.type==="service_pack_card_image")[0].url} loading="lazy"
                                   sizes="(max-width: 479px) 88vw, 340.0000305175781px" srcSet={`${inst?.images.filter(i=>i.type==="service_pack_card_image")[0].url} 500w, ${inst?.images.filter(i=>i.type==="service_pack_card_image")[0].url} 724w`}
                                   alt="" className="rounded-image-small"/>
                          ) : (
                              <div className="image-card-new">
                                <img src={inst?.images.filter(i=>i.type==="service_pack_header_image")[0].url} loading="lazy"
                                   sizes="(max-width: 479px) 88vw, 340.0000305175781px" srcSet={`${inst?.images.filter(i=>i.type==="service_pack_header_image")[0].url} 500w, ${inst?.images.filter(i=>i.type==="service_pack_header_image")[0].url} 724w`}
                                   alt="" className="rounded-image-small"/>
                              </div>
                          )}
                        </>
                    )}
                  </div>
                </div>

            ) : (
                <>
                {inst?.images.filter(i=>i.type==="service_pack_cover_image").length > 0 ? (
                    <div className="image-card-new">
                    <img src={inst?.images.filter(i=>i.type==="service_pack_cover_image")[0].url} loading="lazy"
                         sizes="(max-width: 479px) 88vw, 340.0000305175781px" srcSet={`${inst?.images.filter(i=>i.type==="service_pack_cover_image")[0].url} 500w, ${inst?.images.filter(i=>i.type==="service_pack_cover_image")[0].url} 724w`}
                         alt="" className="rounded-image-small"/>
                    </div>
                ) : (
                    <div className="image-card-new">
                      { inst && inst?.images && inst?.images.filter(i=>i.type==="service_pack_header_image").length > 0 && (
                          <img src={inst?.images.filter(i=>i.type==="service_pack_header_image")[0].url} loading="lazy"
                               sizes="(max-width: 479px) 88vw, 340.0000305175781px" srcSet={`${inst?.images.filter(i=>i.type==="service_pack_header_image")[0].url} 500w, ${inst?.images.filter(i=>i.type==="service_pack_header_image")[0].url} 724w`}
                               alt="" className="rounded-image-small"/>

                      )}
                    </div>
                )}
                 </>
            )}

            {!buyerView && (

                <>
                  <div className="div-block-36" style={{paddingTop: "16px"}}>
                    <div className="columns w-row">


                      <div className="column new no-space w-col w-col-8 w-col-medium-8 w-col-tiny-8 margin-top-auto margin-bottom-auto">
                        <div className="div-block-13 no-space">
                          <img src={eyeDrkGrey} width="20" alt="" className="icon-row" loading="lazy"/>
                          <h5 className="body-text-small no-bottom-space tiny-space-right">{inst?.view_count}</h5>
                        </div>
                      </div>

                      <div className="column-14 no-space w-col w-col-7 w-col-tiny-7">
                        { pending  ? (
                            <div className="">
                              <div className="button-text approval-pending ">Approval pending</div>
                            </div>
                        ) : hideAction ? (
                            <div className="fake-buy-button">
                            </div>
                        )  : canEdit ? (
                            <div className="fake-buy-button  outlinebutton">
                              <div className="button-text dark-green"><Link to={"/edit-service-pack/"+inst?.slug} >Edit this pack</Link></div>
                            </div>
                        )  : canRemove ? (
                            <div className="fake-buy-button  outlinebutton red">
                              <div onClick={(event) => { removeService(event, inst); }} className="button-text  redtext">Remove this pack</div>
                            </div>
                        ) : (
                            <div className="fake-buy-button  lumo-green">
                              <div onClick={(event) => { eventTrigger(event, inst); }} className="button-text  dark-green">Sell this pack</div>
                            </div>
                        )}
                      </div>

                    </div>
                  </div>
                </>

            )}

            {sp && !inst.is_internal && buyerView && (
                <>
                  <div className="card-cta overlap new">
                    <div className="columns-large-new w-row">
                      <div className="column-new w-col w-col-8 w-col-tiny-8">
                        <div className="columns-4 w-row">
                          <div style={{minWidth:"50px"}}className="column-5 w-col w-col-3 w-col-tiny-3">

                            { sp.images.length > 0 && (
                                <img src={sp.images[0].url}  loading="lazy" width="51" alt="" className="image-3" loading="lazy"
                                     style={{borderRadius: "50%", objectFit: "cover", height: "50px",width: "50px", paddingRight: 0}}/>
                            )}
                          </div>
                          <div className="w-col w-col-9 w-col-tiny-9">
                            <div className="text-small dark-gray">{sp?.name}</div>
                            {inst?.pack_reviews && inst?.pack_reviews.length > 0 && 
                             <div className="ratings-section justify-content-start">
                               {starRating(inst?.pack_reviews || [])}
                               <div className="text-small space-left-extra-small">{overallRating(inst?.pack_reviews || [])}</div>
                             </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="column-15 w-col w-col-4 w-col-tiny-4 m-auto">
                        <div className="fake-buy-button">
                          <div onClick={Checkout} className="button-text">Buy</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                     <div className="card-cta overlap new-new empty-square">
                     </div>
                   */}
                </>
            )}

            {sp && inst.is_internal && sp.images.length > 0 && buyerView && (

                <>
                  { inst?.images.filter(i=>i.type==="service_pack_card_image").length > 0 ? (
                      <div className="div-block-36" style={{paddingTop: "16px"}}>
                        <div className="columns w-row">

                          {inst?.coming_soon_at ? (
                              <>
                                <div className="column new no-space w-col w-col-8 w-col-medium-8 w-col-tiny-8">
                                  <div className="div-block-13 no-space">
                                    <h5 className="body-text-small no-bottom-space tiny-space-right">Open for pre-orders</h5>
                                  </div>
                                </div>

                                <div className="column-14 no-space w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                                  <div className="fake-buy-button pre-order">
                                    <div onClick={reserveService} className="button-text">Reserve</div>
                                  </div>
                                </div>
                              </>
                          ) : (
                              <>
                                <div className="column new no-space w-col w-col-8 w-col-medium-8 w-col-tiny-8 margin-top-auto margin-bottom-auto">
                                  <div className="div-block-13 no-space">
                                    <img src={eyeDrkGrey} width="20" alt="" className="icon-row" loading="lazy"/>
                                    <h5 className="body-text-small no-bottom-space tiny-space-right">{inst?.view_count}</h5>
                                  </div>
                                </div>

                                <div className="column-14 no-space w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                                  <div className="fake-buy-button">
                                    <div className="button-text">Buy</div>
                                  </div>
                                </div>
                              </>
                          )}
                        </div>
                      </div>
                  ) : (

                      <>
                        <div className="card-cta overlap new standard-display">
                          <div className="columns-large-new w-row">
                            <div className="column-new w-col w-col-8 w-col-tiny-8 margin-top-auto margin-bottom-auto">
                              <div className="columns-4 w-row">
                                {inst?.coming_soon_at ? (
                                    <div className="div-block-13 no-space">
                                      <h5 className="body-text-small no-bottom-space tiny-space-right">Open for pre-orders</h5>
                                    </div>
                                ) : (
                                    <div className="div-block-13 no-space">
                                      <img src={eyeDrkGrey} width="20" alt="" className="icon-row" loading="lazy"/>
                                      <h5 className="body-text-small no-bottom-space tiny-space-right">{inst?.view_count}</h5>
                                    </div>
                                )}

                              </div>
                            </div>
                            <div className="column-15 w-col w-col-4 w-col-tiny-4 m-auto">
                              {inst?.coming_soon_at ? (
                                  <div className={`fake-buy-button pre-order`}>
                                    <div onClick={reserveService} className="button-text">Reserve</div>
                                  </div>
                              ) : (
                                  <div className={`fake-buy-button `}>
                                    <div onClick={Checkout} className="button-text">Buy</div>
                                  </div>
                              )}

                            </div>
                          </div>
                        </div>

                      </>
                  )}
                </>
            )}

          </a>

        </>
    );
}
