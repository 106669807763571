import { useEffect } from "react";

import IndustrySlider from "../../components/content/IndustrySlider";

import VodacomLogoColour from "../../assets/images/VodacomLogoColour.webp";
import NSBCLogoColour from "../../assets/images/NSBCLogoColour.webp";
import UrbianLogoColour from "../../assets/images/UrbianLogoColour.webp";
import StarFill from "../../assets/images/StarFill.png";
import StarHalfBlk from "../../assets/images/StarHalfBlk.png";
import GIcon from "../../assets/images/GIcon.webp";

import GlobeIcon from "../../assets/images/GlobeIcon.png";
import FacebookBlue from "../../assets/images/FacebookBlue.png";

import ValerrieMAvatar from "../../assets/images/ValerrieMAvatar.png";
import TimCAvatar  from "../../assets/images/TimCAvatar.png";
import BusiMAvatar  from "../../assets/images/BusiMAvatar.png";
import RobynMAvatar  from "../../assets/images/RobynMAvatar.png";
import HelenIAvatar  from "../../assets/images/HelenIAvatar.png";
import SollySAvatar  from "../../assets/images/SollySAvatar.png";
import TitilyoAvatar   from "../../assets/images/TitilyoAvatar.png";
import YoniMAvatar   from "../../assets/images/YoniMAvatar.png";
import tatumNAvatar   from "../../assets/images/tatumNAvatar.png";
import BrentNAvatar   from "../../assets/images/BrentNAvatar.png";
import TraceyKAvatar   from "../../assets/images/TraceyKAvatar.png";
import TimothySAvatar   from "../../assets/images/TimothySAvatar.png";
import DavidSAvatar   from "../../assets/images/DavidSAvatar.png";
import MitchanAAvatar   from "../../assets/images/MitchanAAvatar.png";
import CarolineCAvatar   from "../../assets/images/CarolineCAvatar.png";
import CleoDAvatar   from "../../assets/images/CleoDAvatar.png";
import NigelKAvatar   from "../../assets/images/NigelKAvatar.png";
import DraggableScrollArea from 'draggable-scrollarea';

const HomeMiddleSection = () => {

    useEffect(() => {
        const draggableDiv = new DraggableScrollArea(document.querySelector('.slider-container'));
//        const categoryDiv = new DraggableScrollArea(document.querySelector('.categories-slider'));
    },[]);


    return (
        <>

          <div className="container">
            <div className="logo-row">
              <div>
                <div className="body-text-heading gray">In partnership with:</div>
              </div>
              <img src={VodacomLogoColour} width="175" alt="" className="brand-image colour"/>
              <img src={NSBCLogoColour} width="184" alt="" className="brand-image colour"/>
              <img src={UrbianLogoColour} width="139" alt="" className="brand-image colour"/>
            </div>
          </div>


          <div className="content-width-small-2 center-content space-bottom space">
            <h2 className="title-text-larger less-bottom-space no-format">Join liked-minded entrepreneurs reviving SA's 🇿🇦 economy by growing each others businesses.</h2>
          </div>


          <div className="slider-container space">
            <div className="_20vw-div">
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.9</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"<span className="text-span-65">I have been in the marketing game for 15yrs and have never seen SEO results like Louis &amp; team have achieved</span>..."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={TimCAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Tim Culley</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Founder, Pixalaot</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" alt="" />
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.6</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"It's been really great, <span className="text-span-60">I've seen a lot of increase in sales</span>, in product performance as well as in profits."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={BusiMAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Busi Mavunga</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Love Tea Time</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GlobeIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.8</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"We get up every morning to help the good guys win. <span className="text-span-67">ManyHands gives us access to small to medium businesses at a scale</span> we would never be able to do on our own."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={RobynMAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="column-18 w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Robyn Mays</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Digital Butter</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" alt="" />
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.7</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"I felt that they cared about my business and understood how businesses run. <span className="text-span-61">They were able to deliver exactly what they promised more customers.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={HelenIAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Helene Itzkin</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Video Editor</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GlobeIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.5</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"<span className="text-span-63">From the time that we started, we've only seen an upward trend.</span> It's not only important for income and for revenue and turnover, but also for general business growth."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={SollySAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Solly M Sports</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Sports Apparel Retailer</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" alt="" />
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.6</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"Top Click changed my business! Top Click is great value for money as <span className="text-span-59">they offer so much and at such a reasonable price.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={YoniMAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Yoni Margalit</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Seriel Entrepreneur</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GlobeIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.8</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"I'm very excited to be part of a ZA-grown platform that doesn’t promote the race-to-the-bottom hourly billing of other platforms. <span className="text-span-68">Fixed-prices, curated services and less admin = business growth!</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={BrentNAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Brent Nygaard</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Nygaard Design</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={FacebookBlue} loading="lazy" width="26" alt="" />
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.6</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"They've <span className="text-span-51">completely revolutionized the whole process of legal representation</span>: no hidden costs, no ridiculous retainer fee charged upfront, total transparency and straightforward answers."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={TitilyoAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="column-21 w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Titilayo Adedokun</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">3S Music</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" sizes="(max-width: 767px) 25.99826431274414px, (max-width: 991px) 3vw, (max-width: 1919px) 25.99826431274414px, 1vw" alt="" srcSet="images/GIcon-p-500.png 500w, images/GIcon.png 742w"/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.9</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"Their level of <span className="text-span-58">professionalism and expertise is revolutionary in an era where there are so many apathetic companies.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={TraceyKAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Tracey Catania</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">ORT&nbsp;Jet</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GlobeIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.6</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"The experience [selling on Makro Marketplace] has been nothing short of amazing.<span className="text-span-62"> As a company, we have grown month on month.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={TimothySAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Timothy Smook</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Swoop Trade</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" sizes="(max-width: 767px) 25.99826431274414px, (max-width: 991px) 3vw, (max-width: 1919px) 25.99826431274414px, 1vw" alt="" srcSet="images/GIcon-p-500.png 500w, images/GIcon.png 742w"/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.8</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"Working in the creative industries, it's reassuring to know there's an org like <span className="text-span-52">Legalese that truly understands the nature of what we do and what we need</span>. They were prompt, friendly &amp; professional."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={DavidSAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">David Stein</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">AFDA</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={FacebookBlue} loading="lazy" width="26" alt="" />
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.7</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"Hey Team, I just wanted to take the time to <span className="text-span-64">thank all of you for what you do and what you bring to people's businesses.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={tatumNAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="column-22 w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Tatum Napier</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Apex&nbsp;Waterproofing</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" sizes="(max-width: 767px) 25.99826431274414px, (max-width: 991px) 3vw, (max-width: 1919px) 25.99826431274414px, 1vw" alt="" srcSet="images/GIcon-p-500.png 500w, images/GIcon.png 742w"/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.9</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"Really great to work with a <span className="text-span-53">legal team that is startup focused</span>. They already have context of how we want to be poised and they have the experience to add in clauses foreseeing scenarios that we have not thought of yet."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={MitchanAAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="column-20 w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Mitchan Adams</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Co-founder, Ozow</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" sizes="(max-width: 767px) 25.99826431274414px, (max-width: 991px) 3vw, (max-width: 1919px) 25.99826431274414px, 1vw" alt="" srcSet="images/GIcon-p-500.png 500w, images/GIcon.png 742w"/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.8</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"I highly recommend Ruby Digital for their <span className="text-span-66">outstanding results and genuine commitment to their clients' success.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={CarolineCAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Caroline Coombe-Heath</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">MPU Communications</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GlobeIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.9</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"<span className="text-span-55">I always felt extremely considered and understood.</span> Highly recommend to any small business seeking <span className="text-span-54">legal counsel and not wanting to pay a fortune</span>."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={CleoDAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="column-19 w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Cleo Droomer</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">Fashion&nbsp;Designer</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GIcon} loading="lazy" width="26" alt="" />
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.6</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"Rohan <span className="text-span-69">promised he would exponentially increase the number of inquiries we receive and he has certainly done that.</span>"</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={ValerrieMAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Valerie Morris</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">MD, Renaissance Solar</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-box-quote margin-right new no-bottom-space">
              <div className="slide-text-container less-space">
                <div className="w-clearfix">
                  <div className="wishlist-button">
                    <img src={GlobeIcon} loading="lazy" width="26" alt=""/>
                  </div>
                  <div className="ratings-section-large">
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarFill} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <img src={StarHalfBlk} loading="lazy" width="21" alt="" className="image-star-l"/>
                    <div className="text-mid space-left-extra-small">4.87</div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading-smaller display-inline bold new">"<span className="text-span-56">Probably the best team of attorneys I've dealt with.</span> Excellent quality of work, good communication, prompt replies and <span className="text-span-57">good value for money</span>. I'd definitely recommend them to anyone requiring legal assistance."</h3>
                  </div>
                  <div className="div-block no-bottom-space">
                  </div>
                </div>
              </div>
              <div className="card-cta overlap new-copy">
                <div className="columns-4-copy w-row">
                  <div className="column-5-copy w-col w-col-2 w-col-tiny-2">
                    <img src={NigelKAvatar} loading="lazy" alt="" width="51" className="image-3"/>
                  </div>
                  <div className="w-col w-col-10 w-col-tiny-10">
                    <h5 className="body-text no-bottom-space tiny-space-right bold">Nigel Kukard</h5>
                    <div className="company-name">
                      <h5 className="body-text no-bottom-space tiny-space-right">AllWorldIT</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-delay="4000" data-animation="slide" className="slider-2 w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="true" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true" role="region" aria-label="carousel">
              <div className="mask w-slider-mask" id="w-slider-mask-2">
                <div className="slide-20vw w-slide" aria-label="1 of 8" role="group" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide w-slide" aria-label="2 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide w-slide" aria-label="3 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide w-slide" aria-label="4 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide _350px w-slide" aria-label="5 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide w-slide" aria-label="6 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide w-slide" aria-label="7 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div className="slide w-slide" aria-label="8 of 8" role="group" aria-hidden="true" style={{transform: "translateX(0px)", opacity: 1}}>
                </div>
                <div aria-live="off" aria-atomic="true" className="w-slider-aria-label" data-wf-ignore="">
                </div>
              </div>
              <div className="arrow-container w-slider-arrow-left" role="button" tabindex="0" aria-controls="w-slider-mask-2" aria-label="previous slide" style={{display: "none"}}>
                <div className="icon-8 w-icon-slider-left">
                </div>
              </div>
              <div className="arrow-container w-slider-arrow-right" role="button" tabindex="0" aria-controls="w-slider-mask-2" aria-label="next slide" style={{display: "block"}}>
                <div className="icon-8 w-icon-slider-right">
                </div>
              </div>
              <div className="hide w-slider-nav w-round">
                <div className="w-slider-dot w-active" data-wf-ignore="" aria-label="Show slide 1 of 8" aria-pressed="true" role="button" tabindex="0" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 2 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 3 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 4 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 5 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 6 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 7 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 8 of 8" aria-pressed="false" role="button" tabindex="-1" style={{marginLeft: "3px", marginRight: "3px"}}>
                </div>
              </div>
            </div>
          </div>


          <div className="container no-padding space">
            <div className="content-width-small-2 center-content space-bottom">
              <h2 className="title-text-larger less-bottom-space">Shop our premium service packs for your type of business. </h2>
            </div>
          </div>
          <IndustrySlider/>

        </>

    );
};

export default HomeMiddleSection;
