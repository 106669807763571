import { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { metaData } from '../config/metaData';

import FilteredServicesSlider from '../components/services/FilteredServicesSlider';
import HomeMiddleSection  from "./home/HomeMiddleSection.js";
import HomeDarkSection  from "./home/HomeDarkSection.js";
import HomeServices  from "./home/HomeServices.js";
import ManyHandsInfo  from "../components/content/ManyHandsInfo";
import Countdown  from "../components/content/Countdown";
import HeroFooterBottom  from "../components/content/HeroFooterBottom";
import HomeMobileHeroSection  from "./home/HomeMobileHeroSection";
import GreenCircle from "../assets/images/GreenCircle.png";
import Theo from "../assets/images/TheoHero_1-p-500.webp";
import Thandi from "../assets/images/ThandiHero-p-500.webp";
import Marc from "../assets/images/MarcHero-p-500.webp";

import BlurryLoadingImage from "../components/util/BlurryLoadingImage";

import { MainContext } from '../App';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../assets/images', false, /\.(webp|png|jpe?g|svg)$/));

const HomePage = ({mobileView}) => {

    document.title="ManyHands";


    useEffect(async ()=> {
        document.title = metaData["home"].title;
        document.querySelector("meta[name='description']").content = metaData["home"].description;
        document.querySelector("meta[name='keywords']").content = metaData["home"].keywords;
        
    },[]);


    const observerRef = useRef(null);
    const observerMidRef = useRef(null);
    const carouselRef = useRef(null);
    const starterFadeInRef = useRef(null);
    const {triggerChat} = useContext(MainContext);
    const [loadPageMidElements, setLoadPageMidElements] = useState(false);
    const [loadPageElements, setLoadPageElements] = useState(false);

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            // If intersectionRatio is 0, the target is out of view
            // and we do not need to do anything.
            console.log(entries[0].intersectionRatio);
            if (entries[0].intersectionRatio <= 0) {
                console.log(entries[0].intersectionRatio);
                return;
            }

            setLoadPageMidElements(true);
            unobserver(entries[0]);
        });
        const unobserver = (el) => {
            intersectionObserver.unobserve(el.target);
        };

        if (document.querySelector(".mh-mid-observe")) {
            console.log("START MIDDLE OBSERVE");
            intersectionObserver.observe(observerMidRef.current);
        }

    },[observerMidRef]);



    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            // If intersectionRatio is 0, the target is out of view
            // and we do not need to do anything.
            console.log(entries[0].intersectionRatio);
            if (entries[0].intersectionRatio <= 0) {
                console.log(entries[0].intersectionRatio);
                return;
            }

            setLoadPageElements(true);
            unobserver(entries[0]);
        });
        const unobserver = (el) => {
            intersectionObserver.unobserve(el.target);
        };

        if (document.querySelector(".mh-observe")) {
            console.log("START OBSERVE");
            intersectionObserver.observe(observerRef.current);
        }

    },[observerRef]);

    useEffect(() => {
        let intervalId;

        if (carouselRef && carouselRef.current !== null) {
            // starterFadeInRef.current.classList.add("fade-in");
            // starterFadeInRef.current.classList.remove("fade-out");
            let delay = parseInt(carouselRef.current.dataset.delay);
            let objects = carouselRef.current.querySelectorAll(".w-slide");
            let count = carouselRef.current.querySelectorAll(".w-slide").length;

            intervalId = setInterval(()=>{
                let currIndex = Object.values(objects).indexOf(carouselRef.current.querySelector(".w-slide.fade-in")) || 0;
                let nextIndex = parseInt(currIndex) + 1;
                let currentObject = carouselRef.current.querySelector(".w-slide.fade-in");

                if (nextIndex > count -1) {
                    nextIndex = 0;
                }
                let nextObject = objects[nextIndex];

                nextObject.classList.add("fade-in");
                nextObject.classList.remove("fade-out");
                currentObject.classList.remove("fade-in");
                currentObject.classList.add("fade-out");
                nextObject.ariaHidden = "false";
                currentObject.ariaHidden = "true";

            }, delay);
        }
        return () => {
            clearInterval(intervalId);
        };


    },[carouselRef]);

    return (
        <div>


          {mobileView ? <HomeMobileHeroSection chatTrigger={triggerChat} ImageComponent={BlurryLoadingImage} images={images}/> :

           <div className="section hero-section no-bottom-space">
             <div className="container">
               <div className="w-layout-grid hero-grid-1">
                 <div className="hero-title fixed-width w-clearfix">

                   <h1 className="display-heading-4 dark-green larger">
                     <span className="text-span-50">Grow </span>
                     <span className="text-span-2">with pro </span>
                     <Link to="/discover/services" className="link-7">business services.</Link> Do more with your own&nbsp;<Link onClick={(e)=>{e.preventDefault(); triggerChat();}} className="link-7">AI assistant.</Link>
                   </h1>
                   <div className="div-block-72">
                     <img src={GreenCircle} loading="lazy" width="135" sizes="(max-width: 479px) 100vw, (max-width: 767px) 26vw, 135px" alt="" srcSet={`${images["GreenCircle-p-500.png"]} 500w, ${images["GreenCircle.png"]} 586w`}/>
                   </div>

                   <div className="space-bottom left">
                     <h3 className="sub-heading text-gray-4 less-padding">Easy-to-buy premium services in SA 🇿🇦. Incredible prices. Commission-free. Booked instantly. AI assistant. Plus get 3% cash back on purchases.</h3>
                   </div>
                   <div className="div-block-22-new">
                     <a href="/sign-up#client" className="button-large-left black-green xtra-space w-button">Join for free</a>
                   </div>

                   <div className="div-block-73">
                     <div className="div-block-74 w-clearfix">
                       <img src={images["Avatar1.webp"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 48px" alt="" srcSet={`${images["Avatar1-p-500.webp"]} 500w, ${images["Avatar1.webp"]} 972w`} className="images-stroke"/>
                       <img src={images["Avatar2.webp"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 48px" alt="" srcSet={`${images["Avatar2-p-500.webp"]} 500w, ${images["Avatar2-p-800.webp"]} 800w, ${images["Avatar2.webp"]} 972w`} className="images-stroke overlap"/>
                       <img src={images["Avatar3.webp"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 48px" alt="" srcSet={`${images["Avatar3-p-500.webp"]} 500w, ${images["Avatar3-p-800.webp"]} 800w, ${images["Avatar3.webp"]} 972w`} className="images-stroke overlap-2"/>
                       <img src={images["Avatar4.webp"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 48px" alt="" srcSet={`${images["Avatar4-p-500.webp"]} 500w, ${images["Avatar4-p-800.webp"]} 800w, ${images["Avatar4.webp"]} 972w`} className="images-stroke overlap-3"/>
                       <img src={images["Avatar5.webp"]}  loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 48px" alt="" srcSet={`${images["Avatar5-p-500.webp"]} 500w, ${images["Avatar5-p-800.webp"]} 800w, ${images["Avatar5.webp"]} 972w`} className="images-stroke overlap-4"/>
                       <div className="text-block-20 text-description">531 entrepreneur's recently joined</div>
                     </div>
                   </div>
                 </div>

                 <div ref={carouselRef} data-delay="8000" data-animation="cross" className="hero-slider w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="true" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true" role="region" aria-label="carousel">

                   <div className="mask-2 w-slider-mask manyhands-carousel" id="w-slider-mask-1">
                     <BlurryLoadingImage preview={Theo} ref={starterFadeInRef} alt={"Theo Hero  Image"} imageStyleClass={"slide-5 w-slide fade-in"} image={images["TheoHero_1.webp"]}/>
                     <BlurryLoadingImage preview={Thandi} alt={"Thandi Hero Image"} imageStyleClass={"slide-3 w-slide fade-out"} image={images["ThandiHero.webp"]}/>
                     <BlurryLoadingImage preview={Marc} alt={"Marc Hero Image"} imageStyleClass={"slide-4 w-slide fade-out"} image={images["MarcHero.webp"]}/>
                     <div aria-live="off" aria-atomic="true" className="w-slider-aria-label" data-wf-ignore="">Slide 3 of 3.</div>
                   </div>
                 </div>

               </div>
             </div>
           </div>
          }

          <Countdown/>

          <div ref={observerMidRef} className="mh-mid-observe">
            { loadPageMidElements &&
              <HomeMiddleSection/>
            }
          </div>

          <div ref={observerRef} className="mh-observe">
            { loadPageElements &&
              <>

                <div className="section hero-section equal-top-bottom-space">
                  <HomeDarkSection/>
                </div>


                <div id="LatestServicePacks" className="latest-service-packs-page-anchor"></div>
                <div id="LatestServicesMobile" className="section section-5 wf-section home-page-section-5">

                  <FilteredServicesSlider title1={"The latest."} title2={"Take a look at what's new, right now."} additionalParams={{order_by: "-created_at"}}/>

                  <HomeServices/>

                  <ManyHandsInfo/>


                </div>

                <HeroFooterBottom/>
              </>
            }
          </div>

        </div>

    );
};

export default HomePage;
