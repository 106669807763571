import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import ConfirmPasswordInputField from '../components/util/confirmPasswordInputField';

import logo from "../assets/images/ManyHands-logo.png";
import logo500 from "../assets/images/ManyHands-logo-p-500.png";
import logo800 from "../assets/images/ManyHands-logo-p-800.png";
import logo1080 from "../assets/images/ManyHands-logo-p-1080.png";
import logo1600 from "../assets/images/ManyHands-logo-p-1600.png";
import logo2000 from "../assets/images/ManyHands-logo-p-2000.png";

const ForgotPasswordResetPage = () => {
    const [user, setUser] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        token: '',
    });


    useEffect( () => {
        if ( document.querySelector(".dante-embed-icon") )  {
            document.querySelector(".dante-embed-icon").classList.add("display-none");
        }

    }, []);

    

    const { email, password, passwordConfirm, token} = user;
    const [searchParams] = useSearchParams();

    if (searchParams.get('token')) {
        user.token = searchParams.get('token');
    }

    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (user.password !== user.passwordConfirm) {
            toast('Passwords dont match.');
            return;
        } else {

            updatePassword();
        }

        if ( password !== '' ) {
            // update passworf function
            console.log('reset password function');
        }

    };

    const updatePassword = async () =>{
        let toastid = toast.loading("Resetting your password. Please wait one moment.");
        if (user.user !== '') {

            let response = await axios.put(`/user/password/update/${user.token}`,{
                password: user.password
            });
            console.log(response);

            if ( response ) {
                toast.update(toastid, { render: "Your password has successfully been updated.",
                                        type: "success", isLoading: false, autoClose: 3000 });

                sessionStorage.setItem("passwordUpdated", true);
                window.location = "/sign-in";
            } else {
                toast.update(toastid, { render: "An error occured while resetting your password. Please contact Support for assistance.",
                                        type: "error", isLoading: false, autoClose: 3000 });

            }
        }
    };

    return (
        <>

          <div className="section ">
            <div className="fullscreen-section-content center-content">
              <div className="panel no-shadow transparent-bg max-width">



                <div className="panel-body signup">
                  <Link to="/" className="w-inline-block">
                    <img src={{logo}} loading="lazy" width="200" sizes="(max-width: 479px) 100vw, 200px" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block"/>
                  </Link>
                  <Link to="/" className="w-inline-block">
                    <img src={{logo}} loading="lazy" width="140" sizes="(max-width: 479px) 140px, 100vw" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block mobile"/>
                  </Link>
                  <div className="space-bottom">
                    <h1 className="display-inline">Let's reset your account password!</h1>

                  </div>


                  <div className="form dark-grey w-form">
							      <form onSubmit={onSubmit} id='reset-password-form' className="form-vertical signup">

								      <div
									      className='input-with-icon-left'
									      title='Should be at least 8 characters long'
									      data-tippy-placement='bottom'
								      >
									      <i style={{ top: "unset", bottom: "0" }} className='icon-material-outline-lock'></i>
									      <div className="text-dark-grey text-left">Password</div>
									      <input
										      type='password'
                          className="form-input form-input-large outline w-input margin-top-10"
                          maxlength="256"
										      name='password'
										      value={password}
										      onChange={onChange}
										      placeholder='Password'
										      autoComplete='false'
										      required
									      />
								      </div>

								      <div className='input-with-icon-left  margin-top-15'>
									      <i style={{ top: "unset", bottom: "0" }} className='icon-material-outline-lock'></i>
                        <div className="text-dark-grey text-left">Repeat Password</div>

									      <input
										      type='password'
                          className="form-input form-input-large outline w-input  margin-top-10"
										      name='passwordConfirm'
										      value={passwordConfirm}
										      onChange={onChange}
										      placeholder='Repeat Password'
										      autoComplete='false'
										      required
									      />
								      </div>

								      <button
									      className='button full-width button-sliding-icon ripple-effect margin-top-40'
									      type='submit'
								      >
									      Reset Password{' '}
									      <i className='icon-material-outline-arrow-right-alt'></i>
								      </button>
							      </form>
				          </div>
					      </div>
				      </div>
            </div>
          </div>

        </>
    );
};

export default ForgotPasswordResetPage;
