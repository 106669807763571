
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import jwt from 'jwt-decode';
import { MainContext } from '../../App';
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import IsAuthenticated from "../../components/auth/IsAuthenticated";

import logo from "../../assets/images/ManyHands-logo.png";
import logo500 from "../../assets/images/ManyHands-logo-p-500.png";
import logo800 from "../../assets/images/ManyHands-logo-p-800.png";
import logo1080 from "../../assets/images/ManyHands-logo-p-1080.png";
import logo1600 from "../../assets/images/ManyHands-logo-p-1600.png";
import logo2000 from "../../assets/images/ManyHands-logo-p-2000.png";

export async function TokenLogin(token) {
    try {
        toast('Signing you in...');
        let res = await axios.post('/user/login', { token: token });
        let data = await res.data;
        console.log("SIGN IN DATA: ", data);
        if (data != null && typeof data === 'object') {
            sessionStorage.setItem('authToken', data.authorizationToken);
            let sessionData = jwt(data.authorizationToken);
            let sessionExpire = sessionData['expires_at'];
            let lastLogin = sessionData['last_login'];
            sessionExpire = new Date(sessionExpire);
            sessionExpire.setUTCHours(sessionExpire.getUTCHours() + 2);

            if (data.id != null) {
                try {
                    // get user data by id
                    if (data != null && typeof data === 'object') {
                        //localStorage.clear();
                        console.log("SETTING UP USER");
                        localStorage.setItem('authToken', data.authorizationToken);
                        sessionStorage.setItem('initialise', true);
                        sessionStorage.setItem('initialiseCookie', true);
                        localStorage.setItem('isAuthenticated', true);
                        localStorage.setItem('email', data.email);

                        localStorage.setItem('id', data.id);
                        localStorage.setItem('type', data.type);
                        localStorage.setItem('image', data.profile_image);

                        if (data.profile_image) {
                            localStorage.setItem('image', data.profile_image);
                        }

                        /*			                  if (data.type === 'company') {
                                localStorage.setItem('companyID', data.user_type_id);
                                } else {
                                localStorage.setItem('expertID', data.user_type_id);
                                }*/
                    } else {
                        toast(data);
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            return data;
        } else {
            toast(data);
            return;
        }


    } catch (error) {
        toast(error.data);
        console.log('sign in error: ', error.data);
        return;
    }
};


const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const { context, setContextData, viewTypeTrigger } = useContext(MainContext);
    console.log("ON LOGIN LOAD user: ", context);

    const navigate = useNavigate();

    const urlParam = new URLSearchParams(window.location.search);
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });
    const [token, setToken] = useState(urlParam.get('verify'));
    const [forgotPassword, setForgotPassword] = useState(false);
    const { email, password } = loginData;
    const [sendWelcome, setSendWelcome] = useState(false);


    useEffect(() => {
        document.title = 'Sign in';
        console.log("RUL: " + window.location.href);

        if (sessionStorage.getItem("passwordReset") === "true") {
            toast(
                "An email will be sent to the email address provided, if you have previously signed up. Follow the instructions in the email to reset your password."
            );
            sessionStorage.removeItem("passwordReset");
        }

        if (sessionStorage.getItem("passwordUpdated") === "true") {
            toast(
                "Your password has successfully been reset."
            );
            sessionStorage.removeItem("passwordUpdated");
        }


        if (sessionStorage.getItem("showRegistrationMsg") === "true") {
            toast("You have successfully registered. Follow the instructions sent to your email to verify your account.");
            sessionStorage.removeItem("showRegistrationMsg");
        }

        document.cookie = 'authToken=; Max-Age=0; path=/;';

        if (IsAuthenticated() && urlParam.get("next") !== null && urlParam.get("next").indexOf("/admin") === -1) {

            console.log("ALEADY LOGGED IN...");

            let authtoken = localStorage.getItem('authToken');
            sessionStorage.setItem('authToken', authtoken);
            let sessionData = jwt(authtoken);

            localStorage.setItem('isAuthenticated', true);
            sessionStorage.setItem('initialise', true);
            localStorage.setItem('email', sessionData.email);
            sessionStorage.setItem('userData', sessionData);
            setContextData(sessionData);

            navigate(urlParam.get("next"));
            navigate(0);


        } else if (urlParam.get("next") !== "/Checkout") {

            // removeCookie('authToken');
            // removeCookie('role');
            // removeCookie('email');
            // removeCookie('id');
            // localStorage.clear();
            // sessionStorage.clear();

        } else {
            console.log("checkout");
        }

        if (urlParam.get("verify") && urlParam.get("verify").length > 0) {
            setSendWelcome(true);
            setTimeout(() => { toast.success("Login to verify you email address."); }, 2000);
        }

        setTimeout(() => {
            const head = document.querySelector("head");
            window.danteEmbed = "";
            console.log("LOGIN REMOVE AI SCRIPT");

            if (document.getElementById("data-ai-embed") !== null) {
                console.log(document.getElementById("data-ai-embed"));
                let script = document.getElementById("data-ai-embed");
                head.removeChild(script);
                console.log(head);
            }
            if (document.querySelector(".dante-embed-icon")) {
                document.querySelector(".dante-embed-icon").remove();
                document.querySelector(".dante-embed-chat").style.display = "none";
            }
        }, 500);

    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        let toastid = toast.loading('Signing in...');
        if (email !== '' && password !== '') {

            localStorage.removeItem("authToken");
            localStorage.removeItem("email");
            localStorage.removeItem("id");
            localStorage.removeItem("type");
            localStorage.removeItem("isAuthenticated");
            sessionStorage.removeItem("userData");
            localStorage.removeItem('userstorage');
            localStorage.setItem("viewType", "shopping");

            try {

                let loginDetails = { email, password };
                if (token) {
                    loginDetails["verify"] = token;
                }
                let res = await axios.post('/user/login', loginDetails);
                let data = await res.data;

                console.log("DATA : ", data);
                if (data.success !== undefined && data.success === false) {
                    toast.update(toastid, {
                        render: data.reason,
                        type: "error", isLoading: false, autoClose: 2000
                    });

                } else {
                    console.log("SIGN IN DATA: ", data.body);
                    data = data.body;
                    setContextData(data);
                    let buff = new Buffer(JSON.stringify(data));
                    let base64data = buff.toString('base64');
                    localStorage.setItem('userstorage', base64data);
                    sessionStorage.setItem('authToken', data.authorizationToken);
                    localStorage.setItem('authToken', data.authorizationToken);
                    sessionStorage.setItem('initialise', true);
                    localStorage.setItem('isAuthenticated', true);
                    localStorage.setItem('email', data.email);
                    let sessionData = jwt(data.authorizationToken);
                    sessionStorage.setItem('userData', JSON.stringify(data));
                    let sessionExpire = sessionData['expires_at'];

                    sessionExpire = new Date(sessionExpire);
                    sessionExpire.setUTCHours(sessionExpire.getUTCHours() + 2);

                    setCookie('authToken', data.authorizationToken, {
                        path: '/',
                        expires: sessionExpire,
                    });

                    localStorage.setItem('id', data.id);
                    localStorage.setItem('type', data.type);
                    //localStorage.setItem('viewType', data.type === "client" ? "shopping" : "selling");
                    viewTypeTrigger(data.type === "client" ? "shopping" : "selling", false);
                    localStorage.setItem('isAuthenticated', true);

                    let route = urlParam.get('next');

                    if (urlParam.get('verify') !== null) {
                        console.log("SEND WELCOME EMAIL");
                        let host = process.env.REACT_APP_SITE_HOST; //window.location.host;
                        // let mail_type = localStorage.pre_subscription_type === "plus" ? "manyhands-plus-signup" : localStorage.pre_subscription_type === "pro" ?
                        //     "verified-pro-signup" : data.type === "client" ? "client-sign-up-success" : "service-pro-sign-up-success";
                        let mail_type = data.type === "client" ? "client-sign-up-success" : "service-pro-sign-up-success";

                        let mailer = await axios.post(`/mail/${data.id}/${mail_type}`, {
                            subject: "Welcome to ManyHands!",
                            profile_url: host + "/profile",
                            firstname: "👋",
                            manage_sp_url: host + "/manage-service-packs",
                            sell_existing_sp_url: host + "/sell-service-pack"
                        });
                        console.log("WELCOME EMAIL RSP: ", mailer.data);
                    }

                    toast.update(toastid, {
                        render: "Signing in 👌",
                        type: "success", isLoading: false, autoClose: 1000
                    });

                    if (route !== null) {
                        console.log("REDIRECT ROUTE: ", route);
                        urlParam.forEach((p, i) => { if (i !== "next") { route = route + (route.indexOf("?") === -1 ? "?" : "&") + i + "=" + p; } });
                        let url_param_check = window.location.href.split("?next=")[1];

                        navigate(route);

                    } else if (sessionData.is_admin === true) {
                        navigate('/admin/manage-service-packs');
                    } else if (data.type === "service-provider" && data.profile === null ||
                        data.type === "service-provider" && data.profile.name.trim().length === 0 ||
                        data.type === "client" && data.profile === null ||
                        data.type === "client" && data.profile.contact_first_name === null ||
                        data.type === "client" && data.profile.contact_first_name && data.profile.contact_first_name.trim().length === 0) {
                        localStorage.setItem('onboard', true);
                        console.log("GO TO ONBOARD");
                        if (localStorage.pre_subscription_type || localStorage.service_checkout) {
                            navigate('/checkout');
                        } else {
                            navigate('/profile');
                        }
                        //window.location = '/profile';
                    } else if (route === null) {
                        if (localStorage.onboard === true) {
                            navigate('/profile');
                        } else if (data.type === "client") {
                            navigate('/my-services');
                        } else {
                            navigate('/dashboard');
                        }
                        //window.location = '/dashboard';

                    } else if (localStorage.service_checkout) {
                        localStorage.removeItem("service_checkout")
                        navigate('/checkout');
                    } else {
                        navigate(route);
                    }
                }

            } catch (error) {
                console.log('sign in error: ', error);
                toast.update(toastid, {
                    render: "An error occurred while attempting to sign in. Please confirm your details and retry.",
                    type: "error", isLoading: false, autoClose: 2000
                });
            }

        } else {
            toast.update(toastid, {
                render: "Enter your email and password to sign in.",
                type: "error", isLoading: false, autoClose: 2000
            });
        }

    };

    const onChange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };


    return (
        <div className="section fullscreen-section">
            <div className="fullscreen-section-content center-content">
                <div className="panel no-shadow transparent-bg max-width">
                    <div className="panel-body signup">
                        <Link to="/" className="w-inline-block">
                            <img src={{ logo }} loading="lazy" width="200" sizes="(max-width: 479px) 100vw, 200px" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block" />
                        </Link>
                        <Link to="/" className="w-inline-block">
                            <img src={{ logo }} loading="lazy" width="140" sizes="(max-width: 479px) 140px, 100vw" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block mobile" />
                        </Link>
                        <div className="space-bottom">
                            <h1 className="display-inline">Log in to ManyHands</h1>
                            <div className="account-sign-up-subtext top-space">
                                <div>Don't have an account?&nbsp;</div>
                                <Link to={"/sign-up#service-pro"} className="green-link">Sign up here</Link>
                            </div>
                        </div>

                        <div className="form dark-grey w-form">

                            <form onSubmit={onSubmit} id="wf-form-Sign-In-Form" name="wf-form-Sign-In-Form" data-name="Sign In Form" method="get" className="form-vertical signup" aria-label="Sign In Form">

                                <div autoFocus className="text-dark-grey text-left">Email</div>
                                <input className="form-input form-input-large outline w-input" autofocus="true" maxlength="256"
                                    name="email" placeholder="email@domain.com" type="email" id="email"
                                    required="" value={email} onChange={onChange} />

                                <div className="text-dark-grey text-left">Password</div>
                                <input className="form-input form-input-large outline w-input" maxlength="256" name="password" placeholder="••••••••"
                                    type="password" id="Confirm-Password" required="" value={password} onChange={onChange}
                                />

                                <div className="div-block-64">
                                    <input type="submit" data-wait="Please wait..." className="button button-large w-button" value="Log In" />
                                </div>

                                <div className="account-sign-up-subtext top-space align-middle">
                                    <div className="small-text-bold-2">Forgot your password?&nbsp;</div>
                                    <Link to="/forgot-password" className="green-link">Reset it here</Link>
                                </div>

                            </form>

                            <div className="form-success w-form-done" tabindex="-1" role="region" aria-label="Sign In Form success">
                                <div>Thank you! Your submission has been received!</div>
                            </div>

                            <div className="form-error w-form-fail" tabindex="-1" role="region" aria-label="Sign In Form failure">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
