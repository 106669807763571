import { Link } from 'react-router-dom';
import CardIconOutlineDarkGrey from "../assets/images/CardIconOutlineDarkGrey.png";
import DocIconGrey from "../assets/images/DocIconGrey.png";
import GearIconGrey from "../assets/images/GearIconGrey.png";
import LifeTubeIconGrey from "../assets/images/LifeTubeIconGrey.png";
import ForYouIconDarkGrey from "../assets/images/ForYouIconDarkGrey.png";
import MHIconDrkGrey from "../assets/images/MHIconDrkGrey.png";
import MHPlusIconGreen from "../assets/images/ManyHandsPlus-p-500.png";
import CHatDrkGrey from "../assets/images/CHatDrkGrey.png";
import HeartIconDrkGrey from "../assets/images/HeartIconDrkGrey.png";
import ProfileOutlineIcon from "../assets/images/ProfileOutlineIcon.png";
import LoginIcon from "../assets/images/LoginIcon.png";
import VerifiedDarkGreen from "../assets/images/VerifiedDarkGreen.webp";
import { performLogout } from "./auth/Logout";

const MenuDropdown = ({ authorised, profileImage, userType, slug, email, switchType, viewType, profile }) => {
  const signout = () => {
    performLogout();
  };

  const switchView = () => {
    switchType();
  };

  const onboard = () => {
    localStorage.setItem("onboard", true);
    window.location.href = '/profile';
  };


  return (
    <>
      <nav className="drop-down-menu-list signed-in w-dropdown-list" id="w-dropdown-list-19" aria-labelledby="w-dropdown-toggle-19">
        {authorised ? (

          <>

            <div className="menu-nav-block no-line">
              <Link to={slug ? `/${userType}/${slug}` : "/profile"} className="div-block-profile-info w-inline-block" tabIndex="0">
                <div className="columns-7 w-row">
                  <div className="column-10 w-col w-col-2 w-col-tiny-2">
                    <img src={profileImage} loading="lazy" width="49" sizes="100vw" alt="" className="profile-image-large border-radius-50" style={{ height: "45px", width: "45px", objectFit: "cover" }} />
                  </div>
                  <div className="column-9 w-col w-col-10 w-col-tiny-10">

                    <div style={{ maxWidth: "230px", textOverflow: "ellipsis", overflow: "hidden" }}>{email}</div>
                    {profile && profile.memberships && profile.memberships.length > 0 &&
                      <div className="verified-panel dark-green" style={{ border: 0, paddingLeft: 0 }}>
                        <div className="verified-row">
                          {profile.memberships[0].is_activated && profile.memberships[0].membership_plan.slug.indexOf("pro") > -1 &&
                            <img src={VerifiedDarkGreen} width="16" alt="" className="image-17" loading="lazy" /> 
                          }
                          {profile.memberships[0].is_activated && profile.memberships[0].membership_plan.slug.indexOf("plus") > -1 &&
                            <img src={MHPlusIconGreen} width="105px" alt="" className="image-17" loading="lazy" />
                          }
                          {profile.memberships[0].is_activated && profile.memberships[0].membership_plan.slug.indexOf("pro") > -1 && 
                          <h5 className={"verified-text no-bottom-space dark-green"}>
                            {profile.memberships[0].membership_plan.slug.indexOf("pro") > -1 ? "Verified Pro" : "ManyHands+"}
                          </h5>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </Link>
            </div>

            {viewType === "selling" ? (
              <>
                <div className="menu-nav-block">
                  <Link to="/manage-service-packs" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={CardIconOutlineDarkGrey} loading="lazy" width="17" alt="" />
                    <div className="menu-nav-text">Manage Service Packs</div>
                  </Link>
                  <Link to="/transactions" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={DocIconGrey} loading="lazy" width="17" alt="" />
                    <div className="menu-nav-text">Transaction History</div>
                  </Link>
                  <Link to="/profile" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={GearIconGrey} loading="lazy" width="17" alt="" />
                    <div className="menu-nav-text">Profile</div>
                  </Link>
                  <Link to="/support" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={LifeTubeIconGrey} loading="lazy" alt="" width="17" />
                    <div className="menu-nav-text">Support</div>
                  </Link>
                </div>
                <div className="menu-nav-block">
                  <Link to="/pricing" className="menu-nav-link-text w-dropdown-link" tabIndex="0">Member Plans</Link>
                  {/* <Link to="#" className="menu-nav-link-text w-dropdown-link" tabIndex="0">Refer &amp;&nbsp;Earn</Link> */}
                </div>
                <div className="menu-nav-block">
                  <Link to="#" onClick={signout} className="menu-nav-link-minor w-dropdown-link" tabIndex="0">Sign out</Link>
                </div>

              </>
            ) : (
              <>
                <div className="menu-nav-block">

                  <Link to="/for-you" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={ForYouIconDarkGrey} loading="lazy" width="17" alt="" />
                    <div className="menu-nav-text">For You</div>
                  </Link>
                  <Link to="/profile" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={GearIconGrey} loading="lazy" alt="" width="17" />
                    <div className="menu-nav-text">Profile
                    </div>
                  </Link>
                  <Link to="/my-services" aria-current="page" className="div-block-20 w-inline-block w--current" tabIndex="0">
                    <img src={MHIconDrkGrey} loading="lazy" width="17" alt="" />
                    <div className="menu-nav-text">My Services <span className="text-span-37 hidden">(3)</span>
                    </div>
                  </Link>
                  <Link to="/inbox" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={CHatDrkGrey} loading="lazy" alt="" width="17" />
                    <div className="menu-nav-text">Inbox</div>
                    <div className="infopill hidden">
                      <h3 className="infotext">12</h3>
                    </div>
                  </Link>
                  <Link to="/transactions" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={DocIconGrey} loading="lazy" width="17" alt="" />
                    <div className="menu-nav-text">Transaction History</div>
                  </Link>
                  <Link to="/following" className="div-block-20 w-inline-block" tabIndex="0">
                    <img src={HeartIconDrkGrey} loading="lazy" alt="" width="17" />
                    <div className="menu-nav-text">Following
                    </div>
                  </Link>
                </div>

                <div className="menu-nav-block">
                  {localStorage.type === "client" && <a href="#" onClick={onboard} className="menu-nav-link-text w-dropdown-link" tabIndex="0">Sell Your Services</a>}
                  <Link to="/pricing" className="menu-nav-link-text w-dropdown-link" tabIndex="0">Member Plans</Link>
                </div>
                <div className="menu-nav-block">
                  <Link to="/support" className="menu-nav-link-minor w-dropdown-link" tabIndex="0">Support</Link>
                  <Link to="#" onClick={signout} className="menu-nav-link-minor w-dropdown-link">Sign out</Link>
                </div>
              </>
            )}

            {localStorage.type === "service-provider" &&
              <div className="menu-nav-block">
                <Link to="#" onClick={switchView} className="button green w-button" tabIndex="0">Switch to Service {viewType === "selling" ? "Shopping" : "Selling"}</Link>
              </div>
            }
          </>

        ) : (

          <>
            <div className="menu-nav-block no-line">
              <a href="/sign-up" className="div-block-20 w-inline-block" tabIndex="0">
                <img src={ProfileOutlineIcon} loading="lazy" alt="" width="14" />
                <div className="menu-nav-text">Sign up</div>
              </a>
              <Link to="/sign-in" className="div-block-20 w-inline-block" tabIndex="0">
                <img src={LoginIcon} loading="lazy" alt="" width="12" />
                <div className="menu-nav-text">Log In</div>
              </Link>
            </div>
            <div className="menu-nav-block">
              <Link to="/sell-services" className="menu-nav-link-text w-dropdown-link" tabIndex="0">Sell a Service Pack</Link>
              <Link to="/pricing" className="menu-nav-link-text w-dropdown-link" tabIndex="0">ManyHands Member Plans</Link>
            </div>
            <div className="menu-nav-block">
              <a href="#" className="menu-nav-link-minor w-dropdown-link" tabIndex="0">Partners</a>
              <Link to={"/about-us"} className="menu-nav-link-minor w-dropdown-link" tabIndex="0">About Us</Link>
              <Link to="/support" className="menu-nav-link-minor w-dropdown-link" tabIndex="0">Support</Link>
            </div>

          </>

        )}
      </nav>

    </>
  );
};

export default MenuDropdown;
