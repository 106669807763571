import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Theo from "../../assets/images/TheoHero_1-p-500.webp";
import Thandi from "../../assets/images/ThandiHero-p-500.webp";
import Marc from "../../assets/images/MarcHero-p-500.webp";

// import Theo from "../../assets/images/TheoHeroMobile.webp";
// import Thandi from "../../assets/images/ThandiHeroMobile.webp";
// import Marc from "../../assets/images/MarcHeroMobile.webp";

// function importAll(r) {
//     let images = {};
//     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
//     return images;
// }
// const images = importAll(require.context('../../assets/images', false, /\.(webp|png|jpe?g|svg)$/));

const HomeMobileHeroSection  = ({chatTrigger, ImageComponent, images}) => {
    const starterFadeInRef = useRef(null);
    const mobileCarouselRef = useRef(null);
    useEffect(() => {
        let intervalId;
        if (mobileCarouselRef && mobileCarouselRef?.current) {
            // starterFadeInRef.current.classList.add("fade-in");
            // starterFadeInRef.current.classList.remove("fade-out");
            let delay = parseInt(mobileCarouselRef.current.dataset.delay);
            let objects = mobileCarouselRef.current.querySelectorAll(".w-slide");
            let count = mobileCarouselRef.current.querySelectorAll(".w-slide").length;

            intervalId = setInterval(()=>{
                let currIndex = Object.values(objects).indexOf(mobileCarouselRef.current.querySelector(".w-slide.fade-in")) || 0;
                let nextIndex = parseInt(currIndex) + 1;

                let currentObject = mobileCarouselRef.current.querySelector(".w-slide.fade-in");

                if (nextIndex > count -1) {
                    nextIndex = 0;
                }

                let nextObject = objects[nextIndex];

                nextObject.classList.add("fade-in");
                nextObject.classList.remove("fade-out");
                currentObject.classList.remove("fade-in");
                currentObject.classList.add("fade-out");
                nextObject.ariaHidden = "false";
                currentObject.ariaHidden = "true";

            }, delay);
        }

        return () => {
            clearInterval(intervalId);
        };

    },[mobileCarouselRef]);

    return (

        <div className="section-mobile hero-section no-bottom-space">
          <div className="container no-space">
            <div className="w-layout-grid hero-grid-1">
              <div id="w-node-ad04bdb6-178e-8bd2-6f38-c09a58014b88-76d8bd27" className="hero-title">
                <div className="handwriting-div">
                  <h5 className="handwriting-1 text-primary-3">VERIFIED</h5>
                </div>
                <h1 className="display-heading-4 dark-green padding">Grow with pro&nbsp;
                  <Link to="/discover/services" className="link-8">
                    <span className="text-span-2">business services</span>.
                  </Link> Do more with your own&nbsp;
                  <a href="#" onClick={(e)=> {e.preventDefault(); chatTrigger();}} className="link-8">
                    <span className="text-span-2">AI assistant</span>.
                  </a>
                </h1>
                <div ref={mobileCarouselRef} data-delay="8000" data-animation="cross" className="slider-3 w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="true" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true" role="region" aria-label="carousel">
                  <div className="mask-3 w-slider-mask  manyhands-carousel" id="w-slider-mask-0">

                    <ImageComponent preview={Theo} alt={"Theo loading image"} imageStyleClass={"slide-6 w-slide fade-in"} image={images["TheoHeroMobile.webp"]}/>
                    <ImageComponent preview={Thandi} alt={"Theo loading image"} imageStyleClass={"slide-7 w-slide fade-out"} image={images["ThandiHeroMobile.webp"]}/>
                    <ImageComponent preview={Marc} alt={"Theo loading image"} imageStyleClass={"slide-8 w-slide fade-out"} image={images["MarcHeroMobile.webp"]}/>

                    {/* <img ref={starterFadeInRef} src={Theo} className="slide-5 w-slide fade-out" aria-label="1 of 3" role="group"  aria-hidden="true"/> */}
                    {/* <img src={Thandi} className="slide-3 w-slide fade-out" aria-label="2 of 3" role="group"  aria-hidden="true"/> */}
                    {/* <img src={Marc} className="slide-4 w-slide fade-out" aria-label="3 of 3" role="group"  aria-hidden="true"/> */}
                    {/* <div className="slide-6 w-slide fade-in" aria-label="1 of 3" role="group" aria-hidden="true"> */}
                    {/* </div> */}
                    {/* <div className="slide-7 w-slide fade-out" aria-label="2 of 3" role="group" aria-hidden="true"> */}
                    {/* </div> */}
                    {/* <div className="slide-8 w-slide fade-out" aria-label="3 of 3" role="group"> */}
                    {/* </div> */}
                    <div aria-live="off" aria-atomic="true" className="w-slider-aria-label" data-wf-ignore="">Slide 3 of 3.</div>
                  </div>

                </div>
                <div className="space-bottom padding">
                  <h3 className="sub-heading text-gray-4 padding">Easy-to-buy premium services in SA 🇿🇦. Incredible prices. Commission-free. Booked instantly. And more — plus get 3% cash back on purchases.</h3>
                  <div className="div-block-22-new-mobile">
                    <a href="/sign-up#client" className="button-large-left black-green xtra-space mobile w-button">Get Started</a>
                    {/* <a href="#" className="outline-button w-inline-block"> */}
                    {/*   <div className="div-block-button-large mobile"> */}
                    {/*     <img src={images["PlayArrowIcon.png"]} loading="lazy" width="20" alt="" className="image-20"/> */}
                    {/*     <h3 className="button-text-new">Why join the waitlist?</h3> */}
                    {/*   </div> */}
                    {/* </a> */}
                  </div>
                  <div className="div-block-74 top-space mobile">
                    <img src={images["AvatarsWhiteStrip.png"]} loading="lazy" width="201" sizes="100vw" alt="" srcSet={`${images["AvatarsWhiteStrip-p-500.png"]} 500w, ${images["AvatarsWhiteStrip-p-800.png"]} 800w, ${images["AvatarsWhiteStrip-p-1080.png"]} 1080w, ${images["AvatarsWhiteStrip-p-1600.png"]} 1600w, ${images["AvatarsWhiteStrip-p-2000.png"]} 2000w, ${images["AvatarsWhiteStrip-p-2600.png"]} 2600w, ${images["AvatarsWhiteStrip-p-3200.png"]} 3200w, ${images["AvatarsWhiteStrip.png"]} 4028w`}/>
                    <div className="text-block-20 text-description middle">531 recently joined</div>
                  </div>
                </div>
                <div className="div-block-22-new">
                  <a href="#" className="button-large-left black-green xtra-space w-button">Join the waitlist</a>
                  <div className="div-block-button-large">
                    <h3 className="button-text-new">Why join the waitlist?</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    );
};

export default HomeMobileHeroSection;
