import axios from 'axios';
import { toast } from 'react-toastify';
import Session from 'react-session-api';
import jwt from 'jwt-decode';
import { titleCase } from '../components/util/StringHelper';
import { withCookies, useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
// REGISTER\

export async function register(email, password, type, tsandcs, marketing) {
    try {

        toast.success('Creating Account.');
        let mailNames = '';
        let expertData;
        let companyData;
        // create user

        let res = await axios.post('/user/create', {
            email,
            password,
            type,
            tsandcs,
            marketing
        });
        let registeredData = await res.data;

        if (registeredData != null && typeof registeredData === 'object') {

            if (localStorage.pre_subscription_type || localStorage.service_checkout) {
                let profile = await axios.post(`/service-provider/create/${registeredData.id}`, {});
            }

            let host = process.env.REACT_APP_SITE_HOST; //window.location.host;
            let mailer = await axios.post(`/mail/${registeredData.id}/activate-account`, {
                subject: "Activate your ManyHands account",
                firstname: "",
                mh_url: host,
            });

            console.log("MAIL RESPONSE: ", mailer.data);
            // set localstorage id and email
            localStorage.setItem('id', registeredData.id);
            localStorage.setItem('email', registeredData.email);
            //LoginFunction(email, password);
            toast.success("You have successfully registered. Follow the instructions sent to your email to verify your account.");
            sessionStorage.setItem('showRegistrationMsg', true);

            window.location = '/sign-in';
        } else {
            toast(registeredData);
        }
    } catch (error) {
        toast.error((type === "client" ? "Client" : "Service Pro") + " " + error.response.data[0].reason);
        console.error('register error: ', error.response.data);
    }
}

export const sendMail = async (email, type_of_email) => {
    let res = await axios.post(`/mail/`, {
        type: type_of_email,
        email_to: email,
    });
    let emailResponse = res.data;
};

// LOGIN
export async function LoginFunction(email, password) {
    try {
        toast.success('Signing in...');
        var user = { email, password };

        let res = await axios.post('/user/login', user);
        let data = await res.data;

        if (data.hasOwnProperty('body')) {
            data = JSON.parse(data.body);
        }
        console.log("LOGIN DATA: ", data);
        if (data !== null && typeof data === 'object') {

            console.log(data.success);

            try {
                // get user data by id
                console.log("SIGN IN DATA: ", data);
                sessionStorage.setItem("authToken", data.authorizationToken);

                //localStorage.clear();
                sessionStorage.setItem('initialise', true);
                sessionStorage.setItem('initialiseCookie', true);

                localStorage.setItem('isAuthenticated', true);
                localStorage.setItem('email', data.email);
                localStorage.setItem('id', data.id);
                localStorage.setItem('type', data.type);

                if (data.profile_image) {
                    localStorage.setItem('image', data.profile_image);
                }


                if (sessionStorage.getItem('authToken')) {

                    if (data.profile === null) {
                        window.location = '/profile';
                    } else if (data.type === "client") {
                        window.location = '/my-services';
                    } else {
                        window.location = '/dashboard';
                    }
                } else {
                    toast.error("An error occured while attempting to sign in. Please retry.");
                }

            } catch (error) {
                console.log(error);
            }

        } else {
            toast(data);
        }
    } catch (error) {
        toast.error("An error occured while attempting to sign in. We apologise for the invonvenience.");
        console.log('sign in error: ', error);
    }
}

