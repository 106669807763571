
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {  useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import jwt from 'jwt-decode';
import { MainContext } from '../../App';
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import IsAuthenticated from "../../components/auth/IsAuthenticated";

import logo from "../../assets/images/ManyHands-logo.png";
import logo500 from "../../assets/images/ManyHands-logo-p-500.png";
import logo800 from "../../assets/images/ManyHands-logo-p-800.png";
import logo1080 from "../../assets/images/ManyHands-logo-p-1080.png";
import logo1600 from "../../assets/images/ManyHands-logo-p-1600.png";
import logo2000 from "../../assets/images/ManyHands-logo-p-2000.png";


const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(async () => {
	      document.title = 'Forgot Password';

        setTimeout(()=>{
            const head = document.querySelector("head");
            delete window.danteEmbed;
            console.log("LOGIN REMOVE AI SCRIPT");

            if ( document.getElementById("data-ai-embed") !== null) {
                console.log(document.getElementById("data-ai-embed"));
                let script = document.getElementById("data-ai-embed");
                head.removeChild(script);
                console.log(head);
            }
            if ( document.querySelector(".dante-embed-icon") ){
                document.querySelector(".dante-embed-icon").remove();
                document.querySelector(".dante-embed-chat").style.display="none";
            }
        },500);

    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(email)
        if ( document.querySelector("form").checkValidity() && email !== '') {
            updatePasswordRequest(email);
        } else {
            toast.error('Enter a valid email address');
        }
    };

    const updatePasswordRequest = async (email) => {
        let email_address = email;
        console.log(email)
        if (email_address !== '') {
            let toastid = toast.loading('Generating email link. Please wait one moment...');
            sessionStorage.setItem("passwordResetTrigger", true);
            // run forgot password command
            // user/password/reset_link/{email}
            let response = await axios.post(`mail/reset-password/`, {
                subject: "Let's reset your password",
                username: email.toLowerCase(),
                mh_url: process.env.REACT_APP_SITE_HOST//window.location.host
            });
            let data = await response.data;
            if( await response.status === 200 ){
                sessionStorage.setItem("passwordReset", true);
                console.log(sessionStorage);
                toast.update(toastid, { render: "Success 👌",
                                        type: "success", isLoading: false, autoClose: 1000 });

                navigate('/sign-in');
            } else {
                toast.update(toastid, { render: "An error occurred while attempting to reset your password. Please confirm your details and retry.",
                                        type: "error", isLoading: false, autoClose: 2000 });
            }
        }
    };

    return (
        <div className="section fullscreen-section">
          <div className="fullscreen-section-content center-content">
            <div className="panel no-shadow transparent-bg max-width">
              <div className="panel-body signup">
                <Link to="/" className="w-inline-block">
                  <img src={{logo}} loading="lazy" width="200" sizes="(max-width: 479px) 100vw, 200px" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block"/>
                </Link>
                <Link to="/" className="w-inline-block">
                  <img src={{logo}} loading="lazy" width="140" sizes="(max-width: 479px) 140px, 100vw" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block mobile"/>
                </Link>
                <div className="space-bottom">
                  <h1 className="display-inline">Let's get you back in</h1>

                </div>

                <div className="form dark-grey w-form">

                  <form onSubmit={onSubmit} id="wf-form-Sign-In-Form" name="wf-form-Sign-In-Form" data-name="Sign In Form" method="get" className="form-vertical signup" aria-label="Sign In Form">

                    <div className="text-dark-grey text-left">Email</div>
                    <input className="form-input form-input-large outline w-input" autofocus="true" maxlength="256"
                           name="email" placeholder="email@domain.com" type="email" id="email"
                           required="" value={email} onChange={(e)=>setEmail(e.target.value)}/>

                    <div className="div-block-64">
                      <input type="submit" data-wait="Please wait..." className="button button-large w-button" value="Reset Password"/>
                    </div>

                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
    );
};

export default ForgotPassword;
