import { useState, useEffect, useContext } from 'react';
import { MainContext } from '../App';
import { Link } from 'react-router-dom';
import {titleCase} from "../components/util/StringHelper";

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../assets/images', false, /\.(webp|png|jpe?g|svg)$/));

const MobileView = window.matchMedia('(max-device-width: 479px)').matches ? true : false;

const Footer = ({context, statics, triggerPendingServicesModal}) => {

    const [categories, setCategories] = useState(JSON.parse(localStorage.getItem('categories')));
    const [industries, setIndustries] = useState(JSON.parse(localStorage.getItem('industries')));
    const path = window.location.pathname;

    useEffect( async () => {
        if (statics && statics?.categories && !categories || statics && categories !== statics?.categories) {
            setCategories(statics?.categories);
        } else if (localStorage.categories !== undefined && !categories) {
            setCategories(localStorage.categories);
        }

        if (statics && statics?.industires && !industries || statics && industries !== statics?.industries) {
            setIndustries(statics?.industries);
        } else if (localStorage.industries !== undefined && !industries) {
            setIndustries(localStorage.industries);
        }


    },[statics]);

    useEffect( () => {
        setTimeout(()=>{
            if ( MobileView && context?.id && document.querySelector(".dante-embed-icon") ) {
                if (context?.id && window.location.pathname.includes("/service/")) {
                    document.querySelector(".dante-embed-icon").classList.add("margin-bottom-140");
                } else {
                    //document.querySelector(".dante-embed-icon").classList.add("margin-bottom-50");
                    document.querySelector(".dante-image-wrapper").classList.add("bottom-80");
                }
            }
        }, 2000);
    },[context]);

    const currentMenu = (pathname, current=false) => {
        if (path.indexOf(pathname) > -1) {
            return current ? "w--current pressed" : "pressed";
        }
        return "";
    };
    return (
	      <>
          <div id="footer" className="footer" >
            <div className="container footer">
              <div className="footer-panel">
                <div className="panel-body footer-1-panel-body div-block-25 full-width div-block-40">
                  <div className="content-width-extra-small align-left">
                    <a href="/" className="footer-logo-link w-inline-block">
                      <img src={images["ManyHandsLogoWht.png"]} sizes="165px" srcSet={`${images["ManyHandsLogoWht-p-500.png"]} 500w, ${images["ManyHandsLogoWht-p-800.png"]} 800w, ${images["ManyHandsLogoWht-p-1080.png"]} 1080w, ${images["ManyHandsLogoWht-p-1600.png"]} 1600w, ${images["ManyHandsLogoWht-p-2000.png"]} 2000w, ${images["ManyHandsLogoWht-p-2600.png"]} 2600w, ${images["ManyHandsLogoWht-p-3200.png"]} 3200w, ${images["ManyHandsLogoWht.png"]} 5210w`} alt="" className="footer-logo"/>
                    </a>
                    <div className="text-lead text-green">Make light work</div>
                  </div>
                  <div className="footer-1-top-row">
                    <div className="footer-menu-grids">
                      <div className="w-layout-grid vertical-menu-grid">
                        <div className="text-link text-white">Services Categories</div>
                        <Link to="/discover/services" className="text-link text-green">Discover Service Packs</Link>
                        {categories && categories.map((cat, index)=> (
                            <Link key={"cate_"+index} to={"/services/?category="+cat.slug} className="text-link text-green">{titleCase(cat.name)}</Link>
                        ))}
                      </div>
                      <div className="w-layout-grid vertical-menu-grid">
                        <div className="text-link text-white">Services by Industry</div>
                        <Link to="/discover/services" className="text-link text-green">Discover Service Packs</Link>
                        {industries && industries.map((ind, index)=> (
                            <Link key={"indus_"+index} to={"/services/?industry="+ind.slug} className="text-link text-green">{titleCase(ind.name)}</Link>
                        ))}
                      </div>
                      <div className="w-layout-grid vertical-menu-grid">
                        <div className="text-link text-white">Company</div>
                        <Link to="/about-us" className="text-link text-green">About Us</Link>
                        <Link to="https://blog.manyhands.co.za/post/the-growing-260k-manyhands-partner-network" target="_blank" className="text-link text-green">Partner With Us</Link>
                        <Link to="/sign-up#service-pro" className="text-link text-green">Get Started</Link>
                        <Link to="https://blog.manyhands.co.za" target="_blank" className="text-link text-green">Blog</Link>
                        <Link to="/many-updates" className="text-link text-green">Updates</Link>
                        <Link to="/support" className="text-link text-green">Support</Link>
                        <Link to="/website-terms" className="text-link text-green">Website Terms</Link>
                        <Link to="/terms-of-use" className="text-link text-green">Terms of Use</Link>
                        <Link to="/website-terms#privacy" className="text-link text-green">Privacy Policy</Link>
                        <Link to="/terms-of-use#disputes" className="text-link text-green">Refunds & Disputes</Link>
                      </div>
                      <div className="w-layout-grid vertical-menu-grid">
                        <div className="text-link text-white">Account</div>
                        <Link to="/sign-up" className="text-link text-green">Sign up</Link>
                        <Link to="/sign-in" className="text-link text-green">Login</Link>
                        <Link to="/pricing" className="text-link text-green">Member Plans</Link>
                        <Link to="/sell-services" className="text-link text-green">Sell a Service Pack</Link>
                      </div>
                    </div>
                  </div>
                  <div className="footer-1-bottom-row">
                    <div className="text-small">2022 - 2030 © <span className="text-span-8">ManyHands</span>. All Rights Reserved.<a href="https://www.webflow.com/" target="_blank" rel="noreferrer"></a>
                    </div>
                    <div className="social-list">
                      <a href="https://twitter.com/manyhandsarewe" target="_blank" rel="noreferrer" className="social-link-item w-inline-block">
                        <img src={images["twitter.svg"]} alt=""/>
                      </a>
                      <a href="https://www.instagram.com/manyhandsarewe" target="_blank" rel="noreferrer" className="social-link-item w-inline-block">
                        <img src={images["instagram.svg"]} alt=""/>
                      </a>
                      <a href="https://www.facebook.com/profile.php?id=61558525522282" target="_blank" rel="noreferrer" className="social-link-item w-inline-block">
                        <img src={images["facebook.svg"]} alt=""/>
                      </a>
                      <a href="https://www.linkedin.com/company/manyhandsarewe" target="_blank" rel="noreferrer" className="social-link-item w-inline-block">
                        <img src={images["linkedin.svg"]} alt=""/>
                      </a>
                    </div>
                  </div>

                  <div className="footer-bottom-payment-logo-row">
                    <div className="tiny-text-div bottom-space">
                      <img src={images["VISALogoOutlineWht.png"]} loading="lazy" width="50" sizes="50.000003814697266px" alt="" srcSet={`${images["VISALogoOutlineWht-p-500.png"]} 500w, ${images["VISALogoOutlineWht.png"]} 764w`} className="payicon-div _10px-space"/>
                      <img src={images["VerifiedVISALogoOutlineWht.png"]} loading="lazy" width="50" sizes="50.000003814697266px" alt="" srcSet={`${images["VerifiedVISALogoOutlineWht-p-500.png"]} 500w, ${images["VerifiedVISALogoOutlineWht.png"]} 764w`} className="payicon-div _10px-space"/>
                      <img src={images["MastercardLogoOutlineWht.png"]} loading="lazy" width="50" sizes="50.000003814697266px" alt="" srcSet={`${images["MastercardLogoOutlineWht-p-500.png"]} 500w, ${images["MastercardLogoOutlineWht.png"]} 764w`} className="payicon-div _10px-space"/>
                      <img src={images["MastercardSecuredLogoOutlineWht.png"]} loading="lazy" width="50" sizes="50.000003814697266px" alt="" srcSet={`${images["MastercardSecuredLogoOutlineWht-p-500.png"]} 500w, ${images["MastercardSecuredLogoOutlineWht.png"]} 764w`} className="payicon-div _10px-space"/>
                      <img src={images["ApplePayLogoOutlineWht.png"]} loading="lazy" width="50" sizes="50.000003814697266px" alt="" srcSet={`${images["ApplePayLogoOutlineWht-p-500.png"]} 500w, ${images["ApplePayLogoOutlineWht.png"]} 764w`} className="payicon-div _10px-space"/>
                      <img src={images["PaygateLogoOutlineWht.png"]} loading="lazy" width="50" sizes="50.000003814697266px" alt="" srcSet={`${images["PaygateLogoOutlineWht-p-500.png"]} 500w, ${images["PaygateLogoOutlineWht.png"]} 764w`} className="payicon-div _10px-space"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


          {/*MOBILE FOOTER NAV MENU*/}

          {context?.id && localStorage.viewType === "selling" ? (

              <div className="mobile-selling-nav mobile-nav">
                <div className="panel-nav bg-gray-3">
                  <div className="mobile-bottom-nav panel-body-small">
                    <div className="section-2">
                      <div className="dashboard-div">
                        <Link to="/dashboard" aria-current="page" className="dashboard-button w-inline-block w--current">
                          {currentMenu("/dashboard").length  === 0 ? (
                              <>
                                <img src={images["DasbhboardIconGrey.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Dashboard</div>
                              </>
                          ) : (
                              <>
                                <img src={images["DasbhboardIconFillGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Dashboard</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div className="followers-div">
                        <Link to="/followers" className="followers-button w-inline-block">
                          {currentMenu("/followers").length  === 0 ? (
                              <>
                                <img src={images["SavedIconGray.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Followers</div>
                              </>
                          ) : (
                              <>
                                <img src={images["SavedIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Followers</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div className="my-services-div">
                        <Link to="my-service-packs" className="my-services-button w-inline-block">
                          {currentMenu("/my-service-packs").length  === 0 ? (
                              <>
                                <img src={images["PackStackIconGrey.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">My Packs</div>
                              </>
                          ) : (
                              <>
                                <img src={images["PackStackIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">My Packs</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div className="inbox-div">
                        <Link to="/inbox" className="inbox-button w-inline-block">
                          {currentMenu("/inbox").length  === 0 ? (
                              <>
                                <img src={images["BubbleIconGray.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Inbox</div>
                              </>
                          ) : (
                              <>
                                <img src={images["BubbleIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Inbox</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div  className="for-you-div">
                        <Link onClick={()=>triggerPendingServicesModal(true)} to="#" className="for-you-button w-inline-block">
                          {currentMenu("/profile").length  === 0 ? (
                              <>
                                <img src={images["TrayIconGrey.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Requests</div>
                              </>
                          ) : (
                              <>
                                <img src={images["TrayIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Requests</div>
                              </>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : context?.id && localStorage.viewType === "shopping" ? (

              <div className="mobile-nav mobile-shopping-nav">
                <div className="panel-nav bg-gray-3">
                  <div className="mobile-bottom-nav panel-body-small">
                    <div className="section-2">
                      <div className="dashboard-div">
                        <Link to="/for-you" aria-current="page" className="dashboard-button w-inline-block w--current">
                          {currentMenu("/for-you").length  === 0 ? (
                              <>
                                <img src={images["ForYouIconGrey.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">For You</div>
                              </>
                          ) : (
                              <>
                                <img src={images["ForYouIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">For You</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div className="followers-div">
                        <Link to="/following" className="followers-button w-inline-block">
                          {currentMenu("/following").length  === 0 ? (
                              <>
                                <img src={images["SavedIconGray.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Following</div>
                              </>
                          ) : (
                              <>
                                <img src={images["SavedIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Following</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div className="my-services-div">
                        <Link to="my-services" className="my-services-button w-inline-block">
                          {currentMenu("/my-services").length  === 0 ? (
                              <>
                                <img src={images["PackStackIconGrey.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">My Services</div>
                              </>
                          ) : (
                              <>
                                <img src={images["PackStackIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">My Services</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div className="inbox-div">
                        <Link to="/inbox" className="inbox-button w-inline-block">
                          {currentMenu("/inbox").length  === 0 ? (
                              <>
                                <img src={images["BubbleIconGray.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Inbox</div>
                              </>
                          ) : (
                              <>
                                <img src={images["BubbleIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Inbox</div>
                              </>
                          )}
                        </Link>
                      </div>

                      <div  className="for-you-div">
                        <Link to="/profile" className="for-you-button w-inline-block">
                          {currentMenu("/profile").length  === 0 ? (
                              <>
                                <img src={images["ProfileIconGray.png"]} loading="lazy" alt="" className="image-12 green" width="34"/>
                                <div className="nav-text-gray regular">Profile</div>
                              </>
                          ) : (
                              <>
                                <img src={images["PersonProfileIconGreen.png"]} loading="lazy" width="34" alt="" className="image-12 green"/>
                                <div className="nav-text-green">Profile</div>
                              </>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          ) : (<></>)}


	      </>
    );
};

export default Footer;
