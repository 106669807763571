import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import convertToCurrency from '../../components/util/convertToCurrency';
import moment from "moment";
import { toast } from "react-toastify";
import LoadingGif from "../LoadingGif";

const PendingServiceModal = ({ service_provider_id, closeModal, touchTrigger, setServiceChanges }) => {

    const [pendingServices, setPendingServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noServices, setNoServices] = useState(false);
    const [headerText, setHeaderText] = useState("You have new service purchases!")
    const [headerSubText, setHeaderSubText] = useState("You have 24 hours to respond before the requests expire. You will be able to message the business once you have responded.")

    useEffect(() => {

        getPendingService(service_provider_id);

    }, [service_provider_id])



    const getPendingService = async (service_provider_id) => {
        console.log("Service Pro ID: ", service_provider_id)
        let res = await axios.get(`/profile/service-requests/as-provider/${service_provider_id}/pending`)
        console.log("Get P Service")
        let data = res.data;

        setPendingServices(data);
        setLoading(false);

        if (data.length === 0 && !touchTrigger) {
            closeModal()
        } else if (data.length === 0 && touchTrigger) {
            setNoServices(true)
            setHeaderText("No Service Requests")
            setHeaderSubText("")
        }
    }

    const approveRequest = async (e, service_request_id) => {
        console.log("to approve: /profile/approve-purchase/:basket_id");

        const toastid = toast.loading("Accepting Service Request ...");

        let res = await axios.patch(`/profile/approve-service-request/${service_request_id}`);
        // let res = {data: { is_approved: true }};
        let data = res.data;
        console.log(pendingServices);
        if (data && data.success) {
            let pending = [...pendingServices];
            pending.filter(i => i.id === service_request_id).map(i =>
                i.status = 'pending_accepted'
            );
            setPendingServices(pending);
            setServiceChanges(true)
            toast.update(toastid, {
                render: 'Request has been accepted 👌',
                type: "success", isLoading: false, autoClose: 3000
            });
        } else {
            toast.update(toastid, {
                render: 'An error occured. Cannot accept request.',
                type: "error", isLoading: false, autoClose: 3000
            });
        }

    };

    const declineRequest = async (e, service_request_id) => {
        //to cancel: /profile/cancel-purchase/:basket_id
        e.preventDefault();
        let toastid = toast.loading("Declining service request");
        console.log("DECLINING REQUEST")

        let sendmail = await axios.patch(`/profile/decline-service-request/${service_request_id}`);
        setServiceChanges(true)
        sendmail && sendmail.data.success ?
            toast.update(toastid, {
                render: sendmail.data.message,
                type: "success", isLoading: false, autoClose: 5000
            }) :
            toast.update(toastid, {
                render: sendmail.data.message,
                type: "Error", isLoading: false, autoClose: 5000
            });
        closeModal()
    };


    return (

        <div className="card-body notification-card-body margin-bottom-50">

            {loading ?
                (<LoadingGif extra_class="margin-top-100" />) :
                (
                    <>

                        <h6 className={"medium-heading service-request " + (noServices ? "w-100 margin-auto margin-top-100 text-align-center" : "")}>{headerText}</h6>
                        <div className="body-text-heading space-bottom-large">{headerSubText}</div>

                        {pendingServices && pendingServices.map((s, index) => (
                            <div key={"pending-service-"+index} className="request-panel">
                                <div className="request-panel-body">
                                    <div className="section-3">
                                        <div className="columns-6 w-row">
                                            <div className="column-8 w-col w-col-6">
                                                <div>
                                                    <div className="request-div">
                                                        <h3 className="small-heading request-heading">{s.service_pack__name} • {convertToCurrency(s.basket__transaction_amount / 100)} {!s.service_pack__is_once_off && "p/m"}</h3>
                                                    </div>
                                                </div>
                                                <div className="div-block-14">
                                                    <div className="requester-image">
                                                        <img className="circle rounded-profile-image" src={s.requester__profile_image[0]} loading="lazy" alt="" />
                                                    </div>
                                                    <div className="request-details-div">
                                                        <div className="body-text text-gray-4">
                                                            <Link target="_blank" to={"/client/" + s.requester__slug} className="body-text text-gray-4 smaller-height">bought by&nbsp;
                                                                {s.requester__name.trim() === "" ? s.requester__contact_first_name + " " + s.requester__contact_last_name : s.requester__name}
                                                            </Link></div>
                                                        <div className="body-text text-gray-4">
                                                            {s.requester__location__name && ("from " + s.requester__location__name.join(", ") + ", ")}{s.created_at && moment(s.created_at).fromNow()}
                                                        </div>
                                                        <div className="div-block-16">
                                                            <div className="div-block-15">
                                                                <div className="body-text black">When</div>
                                                            </div>
                                                            <div className="div-block-15">
                                                                <div className="body-text text-gray-4">Service will be delivered within {s.service_pack__delivery_time ? s.service_pack__delivery_time + " days of service acceptance" : "a mutually agreed upon timeframe once accepted"}{!s.service_pack__is_once_off && ", continuing monthly thereafter until cancelled"}.</div>
                                                            </div>
                                                        </div>
                                                        <div className="div-block-16">
                                                            <div className="div-block-15">
                                                                <div className="body-text black">Where</div>
                                                            </div>
                                                            <div className="div-block-15">
                                                                <div className="body-text text-gray-4">{s.requester__location__name && (s.requester__location__name.join(", "))}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {s.status === "pending_accepted" && (
                                                <div className="column-7-message w-col w-col-6">
                                                    <div className="request-response-dv accepted">
                                                        <div className="body-text accept-green">Thank you for accepting the request!</div>
                                                        <div className="body-text text-gray-4 space-bottom">Your response has been sent.</div>

                                                    </div>
                                                </div>
                                            )}

                                            {s.status === "pending" && (
                                                <div className="column-7 w-col w-col-6">
                                                    <div className="div-block-17">
                                                        <div className="request-buttons-div">
                                                            <a onClick={(e) => approveRequest(e, s.id)} href="#" className="button-panel green-accept w-button">Accept</a>
                                                            <a onClick={(e) => declineRequest(e, s.id)} href="#" className="button-panel red-decline w-button">Decline</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </>
                )}



            <img src="images/Close.png" width="28" alt="" className="dismiss-notification-cross" />
        </div>

    )
}

export default PendingServiceModal;