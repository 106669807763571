import {  Link } from "react-router-dom";
import { useEffect, useRef, useState, useContext } from 'react';
import usePasswordValidator from 'react-use-password-validator';
import { register } from '../../functions';
import { toast } from 'react-toastify';
import { MainContext } from '../../App';
import playIcon from "../../assets/images/PlayIconTsnprnt.png";
import playIcon500 from "../../assets/images/PlayIconTsnprnt-p-500.png";
import logo from "../../assets/images/ManyHands-logo.png";
import logo500 from "../../assets/images/ManyHands-logo-p-500.png";
import logo800 from "../../assets/images/ManyHands-logo-p-800.png";
import logo1080 from "../../assets/images/ManyHands-logo-p-1080.png";
import logo1600 from "../../assets/images/ManyHands-logo-p-1600.png";
import logo2000 from "../../assets/images/ManyHands-logo-p-2000.png";

import logoWhite from "../../assets/images/ManyHandsLogoWhite.png";
import logoWhite500 from "../../assets/images/ManyHandsLogoWhite-p-500.png";
import logoWhite800 from "../../assets/images/ManyHandsLogoWhite-p-800.png";
import logoWhite1080 from "../../assets/images/ManyHandsLogoWhite-p-1080.png";
import logoWhite1600 from "../../assets/images/ManyHandsLogoWhite-p-1600.png";
import logoWhite2000 from "../../assets/images/ManyHandsLogoWhite-p-2000.png";

import avatar from "../../assets/images/AvatarsWhiteStrip.png";
import avatar500 from "../../assets/images/AvatarsWhiteStrip-p-500.png";
import avatar800 from "../../assets/images/AvatarsWhiteStrip-p-800.png";
import avatar1080 from "../../assets/images/AvatarsWhiteStrip-p-1080.png";
import avatar1600 from "../../assets/images/AvatarsWhiteStrip-p-1600.png";
import avatar2000 from "../../assets/images/AvatarsWhiteStrip-p-2000.png";
import avatar2600 from "../../assets/images/AvatarsWhiteStrip-p-2600.png";
import avatar3200 from "../../assets/images/AvatarsWhiteStrip-p-3200.png";

import verifiedIcon from "../../assets/images/VerifiedLumoGreen.png";

const WaitlistSignup = () => {


    const [signUpAsClient, setSignUpAsClient] = useState(window.location.hash === "#service-pro" ? false : true);

    useEffect(() => {

        document.title="Sign Up";
        const head = document.querySelector("head");
        // const script = document.createElement("script");
        // script.id = "viral-loops-loader";
        // script.dataset.campaignId = process.env.REACT_APP_VIRAL_LOOPS_CAMPAIGN_ID;;
        // script.src = "https://app.viral-loops.com/widgetsV2/core/loader.js";
        // script.async = true;
        // script.crossOrigin = "anonymous";
        // head.appendChild(script);

        setTimeout(()=>{
            delete window.danteEmbed;

            if ( document.getElementById("data-ai-embed") !== null) {
                let script = document.getElementById("data-ai-embed");
                head.removeChild(script);
            }
            if ( document.querySelector(".dante-embed-icon") ){
                document.querySelector(".dante-embed-icon").remove();
            }
        },1000);

    },[]);

    const changeSignupType = (e)=> {
        e.preventDefault();
        if (!signUpAsClient) {
            setSignUpAsClient(true);
        } else {
            setSignUpAsClient(false);
        }
    };

    return (
        <>

          <div className={`section fullscreen-section bg-light-green`}>
            <div className="fullscreen-section-content">
              <div className="container">
                <div className="w-layout-grid sign-up-grid-halves space-bottom">

                  { signUpAsClient ? (
                        /* <WaitlistSignUp changeType={changeSignupType }/> */
                      <ClientSignUp changeType={changeSignupType }/>
                  ) : (
                      <ServiceProSignUp changeType={changeSignupType }/>
                  )}

                </div>
              </div>
            </div>
          </div>

        </>
    );

};

export default WaitlistSignup;


export const ServiceProSignUp = ({changeType}) => {

    const buttonRef = useRef(null);
    const [user, setUser] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        type: 'service-provider',
        tsandcs: true,
        marketing: true
    });

    const [ isValid, setIsValid ] = usePasswordValidator({
        min: 8,
	      digits: 1,
	      lowercase: true,
	      uppercase: 1,
        spaces: false,
        symbols: 1,
	  });

    const onChange = (e) => {
        if (e.target.name === 'password' || e.target.name === 'passwordConfirm') {
            setIsValid(e.target.value);
        }
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const navigateHome = (e) => {
        document.querySelector("body").classList.remove("bg-light-green");
        buttonRef && buttonRef.current && buttonRef.current.click();
    };

    const emailValidation = (email) => {
        const isEmailValid = /@/.test(email); // use any validator you want
        return isEmailValid;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setIsValid(user.password);
        if (!emailValidation(user.email)) {
            toast.error('Please enter a valid email address.');
            return;
        }

        if (!user.tsandcs) {
            toast.success("Please confirm that you have read ManyHands's Terms and Conditions before registration.");
            return;
        }

        if (!isValid) {
            toast.warn('Your password is required have a minimum of 8 characters, with at least 1 uppercase, 1 symbol and 1 digit.');
            return;
        }

        if (user.password !== user.passwordConfirm) {
            toast.warn('Your passwords do not match.');
            return;
        }

        if (user.email !== '' && user.password !== '') {
            register(user.email.toLowerCase(), user.password, user.type, user.tsandcs, user.marketing);
        }
    };


    return (
        <>

          <div className="panel-body quoteimage">
            <Link to="/" className="w-inline-block">
              <img src={{logoWhite}} loading="lazy" width="200" sizes="(max-width: 479px) 100vw, 200px" alt="" srcSet={`${logoWhite500} 500w, ${logoWhite800} 800w, ${logoWhite1080} 1080w, ${logoWhite1600} 1600w, ${logoWhite2000} 2000w, ${logoWhite} 2600w`} className="logo-div-block"/>
            </Link>
            <div className="div-block-66">
              <div className="space-bottom">
                <div className="card-heading text-white">“I love the shoppable service model, it’s something we are already familiar with so this suits our business perfectly.”</div>
              </div>
              <div className="div-block-65">
                <div className="small-text-bold">Kyle Torrington</div>
                <div className="verified-panel light-green no-bg">
                  <div className="verified-row light-green">
                    <img src={verifiedIcon} loading="lazy" width="16" alt=""/>
                    <h5 className="verified-text no-bottom-space lumo-green">Verified Pro</h5>
                  </div>
                </div>
              </div>
              <div className="text-white white new">
                <div>Legal Legends co-founder</div>
              </div>
            </div>
          </div>

          <div className="panel no-shadow transparent-bg">
            <div className="panel-body signup no-verticle-space">
              <Link to="/" className="w-inline-block">
                <img src={{logo}} loading="lazy" width="140" sizes="(max-width: 479px) 140px, 100vw" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block mobile"/>
              </Link>
              <div className="space-bottom middle-space">
                <h1 className="display-inline">Let's create your account</h1>
                <div className="account-sign-up-subtext regular bottom-space">
                  <div>Already have an account?&nbsp;</div>
                  <Link to="/sign-in" className="green-link">Log in here</Link>
                </div>
                <div className="account-sign-up-subtext top-space bottom-space">
                  <div className="medium-text">Sign up as a:</div>
                </div>
                <div className="_2x-button">
                  <a href="" className="link-block-12 w-inline-block">
                    <div className="fake-buy-button fixed-width padding white">
                      <div onClick={(e)=>changeType(e)}className="button-text white">Client</div>
                    </div>
                  </a>
                  <div className="fake-buy-button fixed-width padding">
                    <div className="button-text">Service Pro</div>
                  </div>
                </div>
              </div>
              <div className="form dark-grey w-form">
                <form id="wf-form-Sign-In-Form" name="wf-form-Sign-In-Form" data-name="Sign In Form" method="get"
                      className="form-vertical signup" aria-label="Sign In Form" onSubmit={onSubmit}>
                  <div className="text-dark-grey">Email</div>
                  <input className="form-input form-input-large outline w-input" autofocus="true" maxlength="256" name="email"
                         placeholder="email@domain.com" type="email" id="email" required=""
                         onChange={onChange} value={user.email}/>

                  <div className="text-dark-grey">Password</div>
                  <input className="form-input form-input-large outline w-input" maxlength="256" name="password"
                         placeholder="8 or more characters eg. Sp@ceX1nc" type="password" id="password" required=""
                         onChange={onChange}
                         value={user.password}/>

                  <div className="text-dark-grey">Confirm Password</div>
                  <input className="form-input form-input-large outline w-input" maxlength="256" name="passwordConfirm"
                         placeholder="Password" type="password" id="confirm-password" required=""
                         onChange={onChange} value={user.passwordConfirm}/>

                  <div className="div-block-64">
                    <input type="submit" data-wait="Please wait..." className="button button-large w-button" value="Sign Up"/>
                  </div>

                  <div className="terms-text">By joining, you agree to the ManyHands <Link to="/terms-of-use" target="_blank">Terms of Service</Link> and to occasionally receive emails from us. Please read our <Link to="/website-terms#privacy" target="_blank">Privacy Policy</Link> to learn how we use your personal data.</div>
                </form>
                <div className="form-success w-form-done" tabindex="-1" role="region" aria-label="Sign In Form success">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="form-error w-form-fail" tabindex="-1" role="region" aria-label="Sign In Form failure">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>

        </>
    );
};



export function videoModal () {

    return (
        <video controls  autoplay="" loop="" playsinline="" data-wf-ignore="true" data-object-fit="cover" loading="lazy" >
          <source src={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyTheWaitlist.mp4"} data-wf-ignore="true"/>
        </video>
    );

};


export const WaitlistSignUp = ({changeType}) => {
    const buttonRef = useRef(null);
    const MobileView = window.matchMedia('(max-device-width: 479px)').matches ? true : false;
    const {mainModalTrigger} = useContext(MainContext);

    const navigateHome = (e) => {
        document.querySelector("body").classList.remove("bg-light-green");
        buttonRef && buttonRef.current && buttonRef.current.click();
    };

    const playVideo = (e) => {
        e.preventDefault();
        mainModalTrigger(videoModal(), "lightbox-modal");
    };

    return (
        <>
          <div className="panel-body video">
            <div className="div-block-66">
              <div className="space-bottom">
                <div className="card-heading text-white large">Another 102 companies joined last week.</div>
              </div>
              <div className="div-block-66">
                <div className="video-window">

                  <a href="#" className="div-block-58 v2 w-inline-block cursor-pointer">
                    <img src={playIcon} loading="eager" width="80" sizes="(max-width: 479px) 100vw, 80px" alt="" srcSet={`${playIcon500} 500w, ${playIcon} 852w`} className="playicon"/>
                  </a>

                  <div className="div-block-96 new">
                    <div className="div-block-97">
                      <div className="card-video-text-heading text-white new">7 mins</div>
                    </div>
                  </div>
                  {!MobileView &&
                   <div data-poster-url={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyWaitlistLoopTrimNoAudio.mp4"} data-video-urls={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyWaitlistLoopTrimNoAudio.mp4"} data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom" onClick={playVideo}>
                    <video autoplay="true" loop muted playsinline="" playsinline data-wf-ignore="true" data-object-fit="cover" loading="lazy">
                      <source src={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyWaitlistLoopTrimNoAudio.mp4"} type="video/mp4"/>
                    </video>
                  </div>
                  }
                </div>
                <div className="card-video-text-heading text-white">ManyHands founder, Anton, takes 7mins, 04 seconds to explain why 100’s of companies in SA 🇿🇦 are joining the waitlist every day.</div>
                <a href="/waitlist" className="w-inline-block">
                  <div className="learn-more-text">Learn more about the waitlist —&gt;</div>
                </a>
              </div>
            </div>
          </div>
          <div className="panel no-shadow transparent-bg">
            <div className="panel-body signup no-verticle-space fixed-height">
              <Link ref={buttonRef} to="/" className="w-inline-block hidden"></Link>
              <a to="#" onClick={navigateHome}className="link-block-19 w-inline-block">
                <img src={{logo}} loading="lazy" width="140" sizes="(max-width: 479px) 140px, 100vw" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block mobile"/>
              </a>
              <div className="space-bottom middle-space">
                <h1 className="display-inline">Join the waitlist</h1>
                <div className="div-block-74 space center-vertical no-bottom-space">
                  <img src={avatar} loading="lazy" width="206" sizes="(max-width: 479px) 79vw, 206px" alt="" srcSet={`${avatar500} 500w, ${avatar800} 800w, ${avatar1080} 1080w, ${avatar1600} 1600w, ${avatar2000}.png 2000w, ${avatar2600} 2600w, ${avatar3200} 3200w, ${avatar} 4028w`}/>
                  <div className="text-block-20 text-description no-space">Join 531 other waitlisters</div>
                </div>
                <div className="account-sign-up-subtext small-top-space bottom-space">
                  <div className="medium-text">Sign up as a:</div>
                </div>
                <div className="_2x-button">
                  <div className="fake-buy-button fixed-width padding">
                    <div className="button-text">Client</div>
                  </div>
                  <a href="#" className="link-block-11 w-inline-block">
                    <div className="fake-buy-button fixed-width padding white">
                      <div onClick={(e)=>changeType(e)} className="button-text white transparent">Service Pro</div>
                    </div>
                  </a>
                </div>
              </div>


              <form-widget ucid='hNukNC9HVP9myjTwtDkW6W00tWw'></form-widget>

            </div>
          </div>

        </>
    );
};

export const ClientSignUp = ({changeType}) => {

    const buttonRef = useRef(null);
    const {mainModalTrigger} = useContext(MainContext);
    const MobileView = window.matchMedia('(max-device-width: 479px)').matches ? true : false;

    const [user, setUser] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        type: 'client',
        tsandcs: true,
        marketing: true
    });

    const [ isValid, setIsValid ] = usePasswordValidator({
        min: 8,
	      digits: 1,
	      lowercase: true,
	      uppercase: 1,
        spaces: false,
        symbols: 1,
	  });


    const onChange = (e) => {
        if (e.target.name === 'password' || e.target.name === 'passwordConfirm') {
            setIsValid(e.target.value);
        }
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const navigateHome = (e) => {
        document.querySelector("body").classList.remove("bg-light-green");
        buttonRef && buttonRef.current && buttonRef.current.click();
    };

    const emailValidation = (email) => {
        const isEmailValid = /@/.test(email); // use any validator you want
        return isEmailValid;
    };

    const playVideo = (e) => {
        e.preventDefault();
        mainModalTrigger(videoModal(), "lightbox-modal");
    };


    const onSubmit = (e) => {
        e.preventDefault();

        setIsValid(user.password);
        if (!emailValidation(user.email)) {
            toast.error('Please enter a valid email address.');
            return;
        }

        if (!user.tsandcs) {
            toast.success("Please confirm that you have read ManyHands's Terms and Conditions before registration.");
            return;
        }

        if (!isValid) {
            toast.warn('Your password is required have a minimum of 8 characters, with at least 1 uppercase, 1 symbol and 1 digit.');
            return;
        }

        if (user.password !== user.passwordConfirm) {
            toast.warn('Your passwords do not match.');
            return;
        }

        if (user.email !== '' && user.password !== '') {
            register(user.email.toLowerCase(), user.password, user.type, user.tsandcs, user.marketing);
        }
    };


    return (
        <>


          <div className="panel-body quoteimage">
            <Link to="/" className="w-inline-block">
              <img src={{logoWhite}} loading="lazy" width="200" sizes="(max-width: 479px) 100vw, 200px" alt="" srcSet={`${logoWhite500} 500w, ${logoWhite800} 800w, ${logoWhite1080} 1080w, ${logoWhite1600} 1600w, ${logoWhite2000} 2000w, ${logoWhite} 2600w`} className="logo-div-block"/>
            </Link>
            <div className="div-block-66">
              <div className="space-bottom">
                <div className="card-heading text-white">“I love the shoppable service model, it’s something we are already familiar with so this suits our business perfectly.”</div>
              </div>
              <div className="div-block-65">
                <div className="small-text-bold">Kyle Torrington</div>
                <div className="verified-panel light-green no-bg">
                  <div className="verified-row light-green">
                    <img src={verifiedIcon} loading="lazy" width="16" alt=""/>
                    <h5 className="verified-text no-bottom-space lumo-green">Verified Pro</h5>
                  </div>
                </div>
              </div>
              <div className="text-white white new">
                <div>Legal Legends co-founder</div>
              </div>
            </div>
          </div>

          {/* <div className="panel-body video">
            <div className="div-block-66">
              <div className="space-bottom">
                <div className="card-heading text-white large">Another 102 companies joined last week.</div>
              </div>
              <div className="div-block-66">
                <div className="video-window">

                  <a href="#" className="div-block-58 v2 w-inline-block cursor-pointer">
                    <img src={playIcon} loading="eager" width="80" sizes="(max-width: 479px) 100vw, 80px" alt="" srcSet={`${playIcon500} 500w, ${playIcon} 852w`} className="playicon"/>
                  </a>

                  <div className="div-block-96 new">
                    <div className="div-block-97">
                      <div className="card-video-text-heading text-white new">7 mins</div>
                    </div>
                  </div>
                  {!MobileView &&
                   <div data-poster-url={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyWaitlistLoopTrimNoAudio.mp4"} data-video-urls={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyWaitlistLoopTrimNoAudio.mp4"} data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom" onClick={playVideo}>
                     <video autoplay="true" loop muted playsinline="" playsinline data-wf-ignore="true" data-object-fit="cover" loading="lazy">
                       <source src={"https://manyhands-storage.ams3.cdn.digitaloceanspaces.com/media/WhyWaitlistLoopTrimNoAudio.mp4"} type="video/mp4"/>
                     </video>
                   </div>
                  }
                </div>
                <div className="card-video-text-heading text-white">ManyHands founder, Anton, takes 7mins, 04 seconds to explain why 100’s of companies in SA 🇿🇦 are joining every day.</div>
              </div>
            </div>
          </div> */}

          <div className="panel no-shadow transparent-bg">
            <div className="panel-body signup no-verticle-space">
              <Link to="/" className="w-inline-block">
                <img src={{logo}} loading="lazy" width="140" sizes="(max-width: 479px) 140px, 100vw" alt="" srcSet={`${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logo1600} 1600w, ${logo2000} 2000w, ${logo} 2600w`} className="logo-div-block mobile"/>
              </Link>
              <div className="space-bottom middle-space">
                <h1 className="display-inline">Let's create your account</h1>
                <div className="account-sign-up-subtext regular bottom-space">
                  <div>Already have an account?&nbsp;</div>
                  <Link to="/sign-in" className="green-link">Log in here</Link>
                </div>
                <div className="account-sign-up-subtext top-space bottom-space">
                  <div className="medium-text">Sign up as a:</div>
                </div>
                <div className="_2x-button">
                  <a href="" className="link-block-12 w-inline-block">
                    <div className="fake-buy-button fixed-width padding">
                      <div className="button-text">Client</div>
                    </div>
                  </a>
                  <div className="fake-buy-button fixed-width padding white">
                    <div onClick={(e)=>changeType(e)} className="button-text white">Service Pro</div>
                  </div>
                </div>
              </div>
              <div className="form dark-grey w-form">
                <form id="wf-form-Sign-In-Form" name="wf-form-Sign-In-Form" data-name="Sign In Form" method="get"
                      className="form-vertical signup" aria-label="Sign In Form" onSubmit={onSubmit}>
                  <div className="text-dark-grey">Email</div>
                  <input className="form-input form-input-large outline w-input" autofocus="true" maxlength="256" name="email"
                         placeholder="email@domain.com" type="email" id="email" required=""
                         onChange={onChange} value={user.email}/>

                  <div className="text-dark-grey">Password</div>
                  <input className="form-input form-input-large outline w-input" maxlength="256" name="password"
                         placeholder="8 or more characters eg. Sp@ceX1nc" type="password" id="password" required=""
                         onChange={onChange}
                         value={user.password}/>

                  <div className="text-dark-grey">Confirm Password</div>
                  <input className="form-input form-input-large outline w-input" maxlength="256" name="passwordConfirm"
                         placeholder="Password" type="password" id="confirm-password" required=""
                         onChange={onChange} value={user.passwordConfirm}/>

                  <div className="div-block-64">
                    <input type="submit" data-wait="Please wait..." className="button button-large w-button" value="Sign Up"/>
                  </div>

                  <div className="terms-text">By joining, you agree to the ManyHands <Link to="/terms-of-use" target="_blank">Terms of Service</Link> and to occasionally receive emails from us. Please read our <Link to="/website-terms#privacy" target="_blank">Privacy Policy</Link> to learn how we use your personal data.</div>
                </form>
                <div className="form-success w-form-done" tabindex="-1" role="region" aria-label="Sign In Form success">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="form-error w-form-fail" tabindex="-1" role="region" aria-label="Sign In Form failure">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>

        </>
    );
};

