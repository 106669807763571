import { useState } from "react";
import { Link } from "react-router-dom";
const Countdown  = () => {

    const [days,setDays] = useState();
    const [hours,setHours] = useState();
    const [minutes,setMinutes] = useState();
    const [seconds,setSeconds] = useState();

    const countDownDate = new Date("January 31, 2025 00:00:00").getTime();

    let x = setInterval(() => {
        // Get today's date and time
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        // If the count down is over, write some text 
        if (distance < 0) {
            clearInterval(x);
        }
    }, 1000);

    return (
        <>


          <div className="section hero-section space-bottom-extra-small no-top-space xtra-padding">
            <div className="container light-green">
              <div className="w-layout-grid hero-2-grid-left">
                <div className="hero-heading new-space">
                  <h1 className="heading-title-2 black-green no-space align">Limited-time offer: <span className="text-span-4">Get up to 6 months free</span>&nbsp;
                    when buying a <Link to="/pricing#client" className="link-6">ManyHands Plan</Link> by<span className="text-span-5 extended"> 31st January 2025. </span>
                  </h1>
                </div>
                <div className="countdown-wrapper">
                  <div className="countdown-item">
                    <div id="js-timer-days" className="clock-number">{days}</div>
                    <div className="clock-label">Days</div>
                  </div>
                  <div className="countdown-item">
                    <div id="js-timer-hours" className="clock-number">{hours}</div>
                    <div className="clock-label">Hours</div>
                  </div>
                  <div className="countdown-item">
                    <div id="js-timer-minutes" className="clock-number">{minutes}</div>
                    <div className="clock-label">Minutes</div>
                  </div>
                  <div className="countdown-item">
                    <div id="js-timer-seconds" className="clock-number">{seconds}</div>
                    <div className="clock-label">Seconds</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </>
    );
};

export default Countdown;
